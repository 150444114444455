import { gql, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { GraphQLError } from "graphql";
import moment from "moment";
import {
  DriversList,
  GraphQLResponse,
  Order,
  OrderChangeResponse,
  OrderInterface,
  OrderListInterface,
} from "../../interfaces/data/OrderRepositoryInterface";
import {
  insertPositionProps,
  SubscribeToOrdersForTableProps,
  updateOrderByIdProps,
  updateOrderDataProps,
} from "../../interfaces/data/OrderRepositoryProps";

export interface GetDistrictWorkload {
  data?: GetDistrictWorkloadData;
  error?: GraphQLError;
}

export interface GetDistrictWorkloadData {
  workloads: DistrictWorkloadData[];
  loadFactor: number;
}

export interface DistrictWorkloadData {
  districtId: string;
  timeperiod: string;
  loadFactor: number;
  workload: number;
}

function _mapOrdersTreeToFlat(ordersTree: any) {
  const prepareOrder = (order: any) => {
    const newOrder = { ...order };

    ['deliveredDate', 'plannedDate', 'updatedAt'].forEach((dateField) => {
      if (newOrder[dateField]) {
        newOrder[dateField] = moment(newOrder[dateField]);
      }
    });

    if (newOrder.client?.address) {
      const { city, district, street } = newOrder.client.address;
      newOrder.deliverTo = [city, district, street].filter(Boolean).join(", ");
    }

    if (newOrder.driver) {
      newOrder.driverName = newOrder.driver.name;
    }

    newOrder.status = newOrder.status?.name;

    // Удаляю поля, ненужные в таблицах
    delete newOrder.driver;
    delete newOrder.address;

    newOrder.key = newOrder.id;

    var products = newOrder.positions.map((el: any) => el.product?.name);
    newOrder.firstPosition = products[0];

    return newOrder;
  };

  return ordersTree.map(prepareOrder);
}

export class OrderRepository {
  client: ApolloClient<NormalizedCacheObject>;
  subscriptions: any = {};

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  get departmentId(): number {
    return parseInt(localStorage.getItem("choosed_department") ?? "1");
  }

  // TODO: доделать интерфейс
  async subscribeToOrdersForTable({
    limit,
    offset,
    where,
    sorters,
    handlers: { onData, onError, onComplete },
  }: SubscribeToOrdersForTableProps): Promise<void> {
    if (this.subscriptions.tableOrders) {
      this.subscriptions.tableOrders.unsubscribe();
    }

    where = { ...where, departmentId: { _eq: this.departmentId } };

    this.subscriptions.tableOrders = this.client
      .subscribe({
        query: GET_ORDERS,
        variables: {
          limit,
          offset,
          where,
          sorters: sorters || { id: "asc" },
        },
      })
      .subscribe({
        next: async (v) => {
          const totalOrError = await this.getTotalOrdersCount({ where });

          if (totalOrError.error) {
            return onError!(totalOrError.error);
          }

          const total = totalOrError.order_aggregate.aggregate.count;

          const orders = _mapOrdersTreeToFlat(v.data.order);

          onData!({ orders, total });
        },
        error: (e: string) => onError!(e),
        complete: () => {
          if (typeof onComplete === "function") {
            onComplete();
          }
        },
      });
  }



  async getStatuses() {
    let result = await this.client.query({
      query: GET_STATUSES,
      variables: { departmentId: this.departmentId },
    });

    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }

    return result.data.status;
  }

  // TODO: указать тип return
  // async getTotalOrdersCount({ where }: SubscribeToOrdersForTableProps) {
  async getTotalOrdersCount({ where }: any) {
    where = { ...where, departmentId: { _eq: this.departmentId } };
    let result = await this.client.query({
      query: GET_TOTAL_ORDERS_WHERE,
      variables: { where: where },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return result.data;
  }

  async getMeta(): Promise<DriversList> {
    let result = await this.client.query({
      query: GET_META,
      variables: { departmentId: this.departmentId },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return result.data;
  }

  async updatePositionQuantity(quantity: number, positionId: number) {
    let result = await this.client.mutate({
      mutation: UPDATE_POSITION_QUANTITY,
      variables: { quantity, positionId },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return result.data.update_order_position_by_pk;
  }

  async deletePosition(id: number) {
    let result = await this.client.mutate({
      mutation: DELETE_POSITION,
      variables: { id },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return result.data.delete_order_position_by_pk;
  }

  async insertUserViewsLog({ userId, orderId }: { userId: string, orderId: number }) {
    let result = await this.client.mutate({
      mutation: INSERT_USER_VIEWS_LOG,
      variables: { orderId, userId },
    });

    if (result.errors) {
      return { error: result.errors[0] as GraphQLError }
    }
    return { ok: true };
  }

  async insertPosition({ orderID, productID, quantity }: insertPositionProps) {
    let result = await this.client.mutate({
      mutation: INSERT_POSITION,
      variables: { orderID, productID, quantity },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return result.data.insert_order_position_one;
  }

  async updateOrderData({
    addressId,
    clientId,
    orderId,
    address,
    client,
    order,
  }: updateOrderDataProps) {
    let result = await this.client.mutate({
      mutation: UPDATE_ALL_ORDER_DATA,
      variables: { addressId, clientId, orderId, address, client, order },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return { ok: true };
  }

  async updateOrderById({ id, order }: updateOrderByIdProps) {
    Object.keys(order).forEach((k) => {
      if (!order[k]) delete order[k];
    });

    let result = await this.client.mutate({
      mutation: UPDATE_ORDER_BY_ID,
      variables: { id: id, order: order },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return { ok: true };
  }

  async updateAddressById(id: number, address: string) {
    let result = await this.client.mutate({
      mutation: UPDATE_ADDRESS_BY_ID,
      variables: { id: id, address: address },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return { ok: true };
  }

  async updateClientById(id: number, client: any) {
    let result = await this.client.mutate({
      mutation: UPDATE_CUSTOMER_BY_ID,
      variables: { id: id, client: client },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return { ok: true };
  }

  async getDistrictWorkload(
    plannedDate: string,
    districtId: string
  ): Promise<GetDistrictWorkload> {
    let result = await this.client.query({
      query: GET_DISTRICT_WORKLOAD,
      variables: {
        plannedDate: moment(plannedDate).format("YYYY-MM-DD"),
        nextDay: moment(plannedDate).add({ days: 1 }).format("YYYY-MM-DD"),
        districtId: districtId,
      },
    });

    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }

    return { data: { workloads: result.data.district_workload as DistrictWorkloadData[], loadFactor: result.data.district_by_pk.loadFactor as number } };
  }

  async getOrderById(id: number): Promise<OrderInterface> {
    let result = await this.client.query({
      query: GET_ORDER_BY_ID_WITH_DRIVERS_WAREHOUSES,
      variables: { id: id, departmentId: this.departmentId },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return mapOrderWithDriversAndWarehouses(result.data);
  }

  async getOrderListById(ids: number[]): Promise<OrderListInterface> {
    let result = await this.client.query({
      query: GET_ORDER_LIST_BY_ID_WITH_DRIVERS_WAREHOUSES,
      variables: { ids: ids, departmentId: this.departmentId },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }

    return mapOrderList(result.data);
  }

  async getOrderChangeHistory(
    orderId: number,
    offset?: number
  ): Promise<GraphQLResponse<OrderChangeResponse>> {
    let result = await this.client.query({
      query: GET_ORDER_HISTORY,
      variables: { orderId, offset: offset || 0 },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }

    return {
      data: {
        changes: result.data.order_change,
        totalCount: result.data.order_change_aggregate.aggregate.count,
        userViewsLogs: result.data.user_views_log,
      },
    };
  }

  //TODO(skyfet): implement types
  async calcOrderPrice(
    orderID: number,
    umbrellaID: string,
    orderItems: any,
    whoDid: string
  ) {
    let result = await this.client.query({
      query: CALC_ORDER_PRICE,
      variables: { orderID, umbrellaID, orderItems, whoDid },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return result.data;
  }

  async editOrderPositions(
    orderID: number,
    umbrellaID: string,
    orderItems: any,
    whoDid: string
  ) {
    let result = await this.client.mutate({
      mutation: EDIT_ORDER_POSITIONS,
      variables: { orderID, umbrellaID, orderItems, whoDid },
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }

    return result.data;
  }


  async getUserDepartments(departmentIds: number[]): Promise<any> {
    let result = await this.client.query({
      query: GET_USER_DEPARTMENTS,
      variables: {
        ids: departmentIds,
      }
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }
    return result.data.department;
  }
}

const GET_ORDER_HISTORY = gql`
  query GetOrderChangeHistory($offset: Int = 0, $orderId: Int!) {
    order_change(
      limit: 20
      offset: $offset
      where: { orderId: { _eq: $orderId } }
      order_by: { createdAt: desc }
    ) {
      editor {
        id
        name
      }
      new
      old
      createdAt
    }
    order_change_aggregate(where: { orderId: { _eq: $orderId } }) {
      aggregate {
        count
      }
    }
    user_views_log(
      where: { orderId: {_eq: $orderId} }
    ) 
    {
      id
      orderId
      userId
      createdAt
      user {
        name
      }
    }
  }
`;

const CALC_ORDER_PRICE = gql`
  query CalcOrderPrice(
    $orderID: Int!
    $umbrellaID: String!
    $orderItems: [OrderItemInput!]!
    $whoDid: String!
  ) {
    recalcOrder(
      orderID: $orderID
      umbrellaID: $umbrellaID
      orderItems: $orderItems
      whoDid: $whoDid
    ) {
      orderItems
      deliveryPrice
    }
  }
`;
const EDIT_ORDER_POSITIONS = gql`
  mutation EditOrder(
    $orderID: Int!
    $umbrellaID: String!
    $orderItems: [OrderItemInput!]!
    $whoDid: String!
  ) {
    editOrder(
      orderID: $orderID
      umbrellaID: $umbrellaID
      orderItems: $orderItems
      whoDid: $whoDid
    ) {
      orderItems
      deliveryPrice
    }
  }
`;

/// GET_ORDERS
const GET_ORDERS = gql`
  query GetOrders(
    $limit: Int = 10
    $offset: Int = 0
    $where: order_bool_exp = {}
    $sorters: [order_order_by!]!
  ) {
    order(order_by: $sorters, limit: $limit, offset: $offset, where: $where) {
      totalCents
      comment
      id
      createdAt
      updatedAt
      shortCode
      resale
      status {
        name
        status_transitions {
          next
        }
      }
      createdAt
      plannedDate
      plannedDateDuration
      deliveredDate
      driver {
        name
        id
      }
      positions {
        product {
          id name
        }
      }
      client {
        id
        phone
        fullname
        districtId
        address {
          city
          comments
          country
          district
          id
          lat
          lng
          street
          state
        }
      }
    }
  }
`;

const GET_TOTAL_ORDERS_WHERE = gql`
  query GetTotalOrdersWhere($where: order_bool_exp = {}) {
    order_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const GET_META = gql`
  query GetMeta($departmentId: Int!) {
    user(
      where: {
        role: { _eq: "driver" }
        blocked: { _eq: false }
        departments_users: { departmentId: { _eq: $departmentId } }
      }
    ) {
      id
      name
    }
  }
`;

const GET_STATUSES = gql`
  query GetStatuses($departmentId: Int!) {
    status {
      name
      orders_aggregate(where: { departmentId: { _eq: $departmentId } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

const GET_ORDER_BY_ID_WITH_DRIVERS_WAREHOUSES = gql`
  query GetOrderByIdWithDriversWarehouses($id: Int!, $departmentId: Int!) {
    order_by_pk(id: $id) {
      id
      comment
      resale
      departmentId
      umbrellaID
      totalCents
      reasonRefusal
      shortCode
      order_photos {
        downloadURL
        id
      }
      status {
        name
        status_transitions {
          next
        }
      }
      plannedDate
      plannedDateDuration
      positions {
        id
        product {
          id
          name
        }
        quantity
        productID
        totalPrice
      }
      client {
        id
        phone
        fullname
        districtId
        address {
          id
          city
          comments
          country
          district
          state
          street
          lat
          lng
        }
      }
      driver {
        id
        name
        email
      }
      warehouse {
        name
        id
      }
    }
    user(
      where: {
        roles: {_contains: "driver"}
        blocked: { _eq: false }
        departments_users: { departmentId: { _eq: $departmentId } }
      }
    ) {
      id
      name
    }
    product(where: {country: {departments: {id: {_eq: $departmentId}}}}) {
      id
      name
    }
  }
`;

const GET_ORDER_LIST_BY_ID_WITH_DRIVERS_WAREHOUSES = gql`
  query GetOrderByIdWithDriversWarehouses($ids: [Int!], $departmentId: Int!) {
    order(where: { id: { _in: $ids } }) {
      id
      comment
      createdAt
      totalCents
      shortCode
      status {
        name
        status_transitions {
          next
        }
      }
      plannedDate
      plannedDateDuration
      client {
        id
        phone
        fullname
        address {
          id
          city
          comments
          country
          district
          state
          street
          lat
          lng
        }
      }
      driver {
        id
        name
        email
      }
      positions {
        product {
          name
          id
        }
        quantity
      }
    }
    user(
      where: {
        role: { _eq: "driver" }
        blocked: { _eq: false }
        departments_users: { departmentId: { _eq: $departmentId } }
      }
    ) {
      id
      name
    }
  }
`;

const UPDATE_ORDER_BY_ID = gql`
  mutation UpdateOrder($id: Int!, $order: order_set_input!) {
    update_order_by_pk(pk_columns: { id: $id }, _set: $order) {
      id
    }
  }
`;
const UPDATE_ADDRESS_BY_ID = gql`
  mutation UpdateAddress($id: Int!, $address: address_set_input!) {
    update_address_by_pk(pk_columns: { id: $id }, _set: $address) {
      id
    }
  }
`;
const UPDATE_CUSTOMER_BY_ID = gql`
  mutation UpdateClient($id: Int!, $client: client_set_input!) {
    update_client_by_pk(pk_columns: { id: $id }, _set: $client) {
      id
    }
  }
`;

const UPDATE_ALL_ORDER_DATA = gql`
  mutation UpdateData(
    $addressId: Int!
    $address: address_set_input!
    $clientId: Int!
    $client: client_set_input!
    $orderId: Int!
    $order: order_set_input
  ) {
    update_address_by_pk(pk_columns: { id: $addressId }, _set: $address) {
      id
    }
    update_client_by_pk(pk_columns: { id: $clientId }, _set: $client) {
      id
    }
    update_order_by_pk(pk_columns: { id: $orderId }, _set: $order) {
      id
    } 
  }
`;

const UPDATE_POSITION_QUANTITY = gql`
  mutation UpdatePositionQuantity($quantity: Int!, $positionId: Int!) {
    update_order_position_by_pk(
      pk_columns: { id: $positionId }
      _set: { quantity: $quantity }
    ) {
      id
    }
  }
`;

const DELETE_POSITION = gql`
  mutation DeletePosition($id: Int!) {
    delete_order_position_by_pk(id: $id) {
      id
    }
  }
`;

const GET_DISTRICT_WORKLOAD = gql`
  query MyQuery(
    $plannedDate: timestamptz!
    $nextDay: timestamptz!
    $districtId: String!
  ) {
    district_workload(
      where: {
        timeperiod: { _gte: $plannedDate, _lt: $nextDay }
        districtId: { _eq: $districtId }
      }
    ) {
      districtId
      loadFactor
      timeperiod
      workload
    }
    district_by_pk(id: $districtId) {
      loadFactor
    }
  }
`;

const INSERT_POSITION = gql`
  mutation InsertPosition(
    $orderID: Int!
    $productID: String!
    $quantity: Int!
  ) {
    insert_order_position_one(
      object: { orderID: $orderID, productID: $productID, quantity: $quantity }
    ) {
      id
    }
  }
`;

const INSERT_USER_VIEWS_LOG = gql`
  mutation InsertUserViewsLog($userId: String!, $orderId: Int) {
    insert_user_views_log(objects: {orderId: $orderId, userId: $userId}) {
      affected_rows
    }
  }
`;


const GET_USER_DEPARTMENTS = gql`
  query getDepartments($ids: [Int!]) {
    department(where: {id: {_in: $ids}}) {
      id
      name
      currency
      language
      timeZone
    }
  }
`;

function mapOrderWithDriversAndWarehouses(json: any): OrderInterface {
  json = { ...json };
  json.order = { ...json.order_by_pk };
  json.drivers = json.user;
  json.products = json.product;
  delete json.order_by_pk;
  delete json.warehouse;
  delete json.user;
  delete json.product;

  return json;
}

function mapOrderList(json: any): OrderListInterface {
  let orderList = { ...json };
  orderList.orders = [...orderList.order];
  delete orderList.order;

  orderList.orders = orderList.orders.map((order: Order) => {
    let newOrder = { ...order };
    return newOrder;
  });

  orderList.drivers = json.user;
  return orderList;
}
