import { Spin } from "antd";

import { observer } from "mobx-react-lite";
import { OrderDrawerState } from "../../../mobx/OrderDrawer";

import { OrderDrawerViewProps } from "../../../interfaces/views/props/OrderDrawerViewProps";

import { OrderDrawerForm } from "./OrderDrawerForm/OrderDrawerForm";

import { OrderDrawerMultiForm } from "./OrderDrawerForm/OrderDrawerMultiForm";

import "../../../styles/drawer";
import "../../../styles/drawer.css";
import { OrderDrawerStatusConfirmModal } from "./OrderDrawerForm/components/OrderDrawerStatusConfirmModal";

export const OrderDrawerView: React.FC<OrderDrawerViewProps> = observer(
  ({ drawerStore }) => {
    var component;

    switch (drawerStore.state) {
      case OrderDrawerState.loading:
        return (
          <div className="CenteredSpin">
            <Spin />
          </div>
        );
      case OrderDrawerState.form:
        component = <OrderDrawerForm drawerStore={drawerStore} />;
        break;
      case OrderDrawerState.multi:
        component = <OrderDrawerMultiForm drawerStore={drawerStore} />;
        break;
      default:
        return <div>Undefined state</div>;
    }
    return (
      <>
        <OrderDrawerStatusConfirmModal drawerStore={drawerStore} />
        {component}
      </>
    );
  }
);
