import { Button, Select } from "antd";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { observer } from "mobx-react-lite";
import React from "react";
import { OrderDrawerViewProps } from "../../../../interfaces/views/props/OrderDrawerViewProps";
import { OrderExcelMultiView } from "./components/OrderExcelView";
import { PDFLinkButton } from "./components/PDFLinkButton";
import { layoutFormProps } from "./layout";

const { Option } = Select;

export const OrderDrawerMultiForm: React.FC<OrderDrawerViewProps> = observer(
  ({ drawerStore }) => {
    let form = drawerStore.form;

    const departments = window.UserData.departments;
    const defaultDepartment = departments.find((value) => {
      return value.id === parseInt(localStorage.getItem("choosed_department")!);
    });

    const isManager = window.UserData.roles.includes("manager");

    return (
      <Form
        {...layoutFormProps}
        size="small"
        onFinish={(v) => drawerStore.onMultiFormSubmit(v)}
      >
        <div
          style={{ display: "flex", justifyContent: "center", gap: "0 10px" }}
        >
          <h2 style={{ textAlign: "center" }}>
            Selected {form.orders!.length} orders{" "}
          </h2>
          <PDFLinkButton form={form} />
          <OrderExcelMultiView form={form} />
        </div>
        <FormItem label="Driver" name="driverID">
          <Select>
            {form.drivers!.map((driver) => (
              <Select.Option value={driver.id} key={driver.id}>
                {driver.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="Status" name="statusName">
          <Select disabled={!drawerStore.canChangeStatus}>
            <Select.Option
              value={form.orders![0]!.status!.name}
              key={form.orders![0]!.status!.name}
            >
              {form.orders![0]!.status!.name}
            </Select.Option>
            {form.orders![0]!.status!.status_transitions.map((transition) => (
              <Select.Option value={transition.next} key={transition.next}>
                {transition.next}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        {isManager ? (
          <>
            <FormItem
              label="Department"
              name={["departmentId"]}
              preserve={false}
            >
              <Select
                defaultValue={`${defaultDepartment?.name ?? "No departments"}`}
                style={{ width: "150px", display: "inline-block" }}
              >
                {departments.map((department, index) => (
                  <Option key={index} value={department.id}>
                    {department.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </>
        ) : (
          <></>
        )}

        <FormItem>
          <Button type="primary" htmlType="submit" children="Submit" />
        </FormItem>
      </Form>
    );
  }
);
