import React from "react";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { signOut } from "../core/providers/AuthFirebaseProvider";

const LogoutButtonView: React.FC = () => {

  return (
    <Button
      style={{
        border: "none",
        padding: "0 5px",
        marginLeft: "auto",
        display: "inline-block",
        position: "absolute",
        right: 15,
        top: 15,
      }}
      shape="circle"
      icon={<LogoutOutlined />}
      onClick={async () => await signOut()}
    />
  );
};

export default LogoutButtonView;
