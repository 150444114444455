import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { OrderInterface } from "../../../../../interfaces/data/OrderRepositoryInterface";
import { OrderPDFMultiView } from "./OrderPDFView";
import { PrinterOutlined } from "@ant-design/icons";

export const PDFLinkButton = observer(({ form }: { form: OrderInterface }) => (
  <div>
    <PDFDownloadLink
      document={<OrderPDFMultiView form={form} />}
      fileName="orders.pdf"
    >
      {({ loading }) =>
        loading ? (
          <Spin />
        ) : (
          <Button type="primary" shape="circle" icon={<PrinterOutlined />} />
        )
      }
    </PDFDownloadLink>
  </div>
));
