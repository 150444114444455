import {
  gql,
  ApolloClient,
  NormalizedCacheObject,
  ObservableSubscription,
} from "@apollo/client";
import { Driver } from "../../interfaces/data/TrackingRepositoryInterface";
import { DateRangeProps } from "../../interfaces/stores/props/DateRangePropsInterface";
import { DateRangeWithIdProps } from "../../interfaces/stores/props/DateRangeWithIdPropsInterface";
import { SubscriptionHandlerProps } from "../../interfaces/stores/props/SubscriptionHandlerProps";

interface NamedSubscription {
  name: string;
  subscription: ObservableSubscription;
}

export default class TrackingDataSource {
  private subscriptions: NamedSubscription[] = [];
  client: ApolloClient<NormalizedCacheObject>;

  constructor(client: ApolloClient<NormalizedCacheObject>) {
    this.client = client;
  }

  subscribeToDriversWithLastTrackPoint({
    onData,
    onError,
    dateFrom,
    dateTo,
  }: DateRangeProps &
    SubscriptionHandlerProps<Driver[]>): ObservableSubscription {
    let subscription = this.client
      .subscribe({
        query: GET_DRIVERS_WITH_LAST_TRACK_POINT,
        variables: { dateFrom, dateTo, departmentId: this.departmentId },
      })
      .subscribe(
        (result) => onData(result.data.user),
        (err) => onError && onError(err)
      );

    this.updateSubscription("driver-with-last-trackpoint", subscription);

    return subscription;
  }

  async getDriverWithAddressByDate({
    id,
    dateFrom,
    dateTo,
  }: DateRangeWithIdProps): Promise<Driver> {
    let result = await this.client.query({
      query: GET_DRIVERS_WITH_ADDRESS_BY_DATE,
      variables: { dateFrom, dateTo, id, departmentId: this.departmentId },
    });
    if (result.errors) {
      throw result.errors[0];
    }
    return result.data.user[0];
  }

  private updateSubscription(
    name: string,
    subscription: ObservableSubscription
  ) {
    let s = this.subscriptions.find((s) => s.name === name);
    if (s) {
      if (!s.subscription.closed) s.subscription.unsubscribe();
      delete this.subscriptions[this.subscriptions.indexOf(s)];
    }
    this.subscriptions.push({ name, subscription });
  }

  private get departmentId(): number {
    return parseInt(localStorage.getItem("choosed_department") ?? "1");
  }
}

const GET_DRIVERS_WITH_LAST_TRACK_POINT = gql`
  subscription GetDriversWithTrackPoints(
    $dateFrom: timestamptz!
    $dateTo: timestamptz!
    $departmentId: Int!
  ) {
    user(
      where: {
        role: { _eq: "driver" }
        orders: { plannedDate: { _gte: $dateFrom, _lt: $dateTo } }
        departments_users: { departmentId: { _eq: $departmentId } }
      }
    ) {
      deliveredOrdersCount(args: { date_from: $dateFrom, date_to: $dateTo })
      rescheduledOrdersCount(args: { date_from: $dateFrom, date_to: $dateTo })
      returnedOrdersCount(args: { date_from: $dateFrom, date_to: $dateTo })
      name
      id
      email
      track_points(
        where: { time: { _gte: $dateFrom, _lt: $dateTo } }
        order_by: { id: desc }
        limit: 1
      ) {
        time
        lng
        lat
        id
        batteryLevel
      }
    }
  }
`;

const GET_DRIVERS_WITH_ADDRESS_BY_DATE = gql`
  query getDriversWithAddressByDate(
    $id: String!
    $dateFrom: timestamptz!
    $dateTo: timestamptz!
    $departmentId: Int!
  ) {
    user(
      where: {
        id: { _eq: $id }
        role: { _eq: "driver" }
        departments_users: { departmentId: { _eq: $departmentId } }
      }
    ) {
      id
      name
      email
      orders(
        where: { plannedDate: { _gte: $dateFrom, _lt: $dateTo } }
        order_by: { plannedDate: desc }
      ) {
        id
        shortCode
        plannedDate
        plannedDateDuration
        statusName
        totalCents
        createdAt
        client {
          fullname
          phone
          address {
            city
            comments
            country
            district
            lat
            lng
            street
            state
            id
          }
        }
        positions {
          totalPrice
          quantity
          product {
            name
          }
        }
      }
    }
  }
`;
