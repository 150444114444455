import { observer } from "mobx-react-lite";
import PlacesAutocomplete from "react-places-autocomplete";

import { Button, Input } from "antd";
import { LocationSearchInputViewInterface } from "../../../interfaces/views/props/LocationSearchInputViewProps";

const searchOptions = {
  types: ["(cities)"],
  componentRestrictions: { country: ["pe"] },
  fields: ["geometry", "address_components"],
};

export const LocationSearchInputView = observer(
  ({
    controller,
    options,
    inputProps,
    onManually,
  }: LocationSearchInputViewInterface) => {
    options = { ...searchOptions, ...options };
    return (
      <PlacesAutocomplete
        value={controller.searchText || ""}
        debounce={200}
        onSelect={(suggestion) => controller.onSelect(suggestion)}
        onChange={(v) => controller.onChange(v)}
        searchOptions={options}
        children={({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }) => (
          <div style={{ position: "relative" }}>
            <Input
              {...getInputProps({
                placeholder: "Start typing District or City...",
              })}
              onKeyDown={() => {}}
              onBlur={() => {}}
              {...inputProps}
            />
            <p
              className={
                "autocomplete-dropdown-container " +
                (controller.dropdownHidden ? "hidden" : "")
              }
            >
              {loading && <div className="suggestion-item">Loading...</div>}
              {
                // Если не загрузка, в поиске введён адрес, но нет результатов
                !loading &&
                  !controller.dropdownHidden &&
                  Object.keys(suggestions).length === 0 && (
                    <div className="suggestion-item">No results</div>
                  )
              }
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    onClick={() => controller.onSelect(suggestion)}
                    key={suggestion.index}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
              {onManually && (
                <div className="autocomplete-dropdown-container-tip suggestion-item">
                  <span>Couldn't find the address?</span>
                  <Button
                    type="link"
                    style={{ fontSize: "1em" }}
                    onClick={() => onManually()}
                  >
                    <span>Enter manually →</span>
                  </Button>
                </div>
              )}
            </p>
          </div>
        )}
      />
    );
  }
);
