import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Err, Ok, Result } from "@hqoss/monads";
import { Failure, GraphQLFailure } from "../../core/failures/failures";
import {
  AllDistrictsAndDriversWithDistricts,
  DistrictWithDrivers,
} from "../../interfaces/data/DistrictsRepositoryInterface";
import DistrictsDataSource from "../datasource/districts_datasource";

export class DistrictsRepository {
  districtsDataSource: DistrictsDataSource;

  constructor(client: ApolloClient<NormalizedCacheObject>) {
    this.districtsDataSource = new DistrictsDataSource(client);
  }

  async getDriversDistrictList(): Promise<
    Result<AllDistrictsAndDriversWithDistricts, Failure>
  > {
    try {
      const result =
        await this.districtsDataSource.getDriverDistrictRelationList();
      return Ok(result);
    } catch (e) {
      return Err(GraphQLFailure(e));
    }
  }

  async deleteDriverDistrict(
    districtId: string,
    driverId: string
  ): Promise<Result<null, Failure>> {
    try {
      await this.districtsDataSource.deleteDriverDistrict(districtId, driverId);
      return Ok(null);
    } catch (e) {
      return Err(GraphQLFailure(e));
    }
  }

  async deleteDistrictDriver(
    districtId: string,
    driverId: string
  ): Promise<Result<null, Failure>> {
    try {
      await this.districtsDataSource.deleteDriverDistrict(districtId, driverId);
      return Ok(null);
    } catch (e) {
      return Err(GraphQLFailure(e));
    }
  }

  async addDriverDistrict(
    districtId: string,
    driverId: string
  ): Promise<Result<null, Failure>> {
    try {
      await this.districtsDataSource.insertDriverDistrict(districtId, driverId);
      return Ok(null);
    } catch (e) {
      return Err(GraphQLFailure(e));
    }
  }

  async updateDistrictLoadFactor(
    loadFactor: number,
    districtId: string,
  ): Promise<Result<null, Failure>> {
    try {
      await this.districtsDataSource.updateDistrictLoadFactor(loadFactor, districtId);
      return Ok(null);
    } catch (e) {
      return Err(GraphQLFailure(e));
    }
  };

  async updateDistrictDeliveryDays(
    deliveryDays: number,
    districtId: string,
  ): Promise<Result<null, Failure>> {
    try {
      await this.districtsDataSource.updateDistrictDeliveryDays(deliveryDays, districtId);
      return Ok(null);
    } catch (e) {
      return Err(GraphQLFailure(e));
    }
  };

  async updateDistrictOffDays(
    offDays: number[],
    districtId: string,
  ): Promise<Result<null, Failure>> {
    try {
      console.log(offDays)
      await this.districtsDataSource.updateDistrictOffDays(offDays, districtId);
      return Ok(null);
    } catch (e) {
      return Err(GraphQLFailure(e));
    }
  };

  async updateDistrictEnabled(
    districtId: string,
    enabled: boolean
  ): Promise<Result<null, Failure>> {
    try {
      await this.districtsDataSource.updateDistrictEnabled(districtId, enabled);
      return Ok(null);
    } catch (e) {
      return Err(GraphQLFailure(e));
    }
  }

  async getDistrictsDriverList(): Promise<
    Result<Array<DistrictWithDrivers>, Failure>
  > {
    try {
      const result = (
        await this.districtsDataSource.getDistrictDriverRelationList()
      );
      return Ok(result);
    } catch (e) {
      return Err(GraphQLFailure(e));
    }
  }
}
