import { ApolloClient, ObservableSubscription } from "@apollo/client";
import { Result, Err, Ok } from "@hqoss/monads";
import { GraphQLError } from "graphql";
import {
  AnyFailure,
  Failure,
  GraphQLFailure,
} from "../../core/failures/failures";
import { Driver } from "../../interfaces/data/TrackingRepositoryInterface";
import { DateRangeProps } from "../../interfaces/stores/props/DateRangePropsInterface";
import { DateRangeWithIdProps } from "../../interfaces/stores/props/DateRangeWithIdPropsInterface";
import { SubscriptionHandlerProps } from "../../interfaces/stores/props/SubscriptionHandlerProps";
import TrackingDataSource from "../datasource/tracking_datasource";

export default class TrackingRepository {
  trackingDataSource: TrackingDataSource;
  constructor(client: ApolloClient<any>) {
    this.trackingDataSource = new TrackingDataSource(client);
  }

  async getDriversWithLastTrackPoint({
    onData,
    onError,
    dateFrom,
    dateTo,
  }: DateRangeProps & SubscriptionHandlerProps<Driver[]>): Promise<
    Result<ObservableSubscription, Failure>
  > {
    try {
      const result =
        this.trackingDataSource.subscribeToDriversWithLastTrackPoint({
          onData,
          onError,
          dateFrom: dateFrom,
          dateTo: dateTo,
        });
      return Ok(result);
    } catch (e) {
      if (e instanceof GraphQLError) return Err(GraphQLFailure(e));
      return Err(AnyFailure(e));
    }
  }

  async getDriversWithAddressByDate({
    id,
    dateFrom,
    dateTo,
  }: DateRangeWithIdProps): Promise<Result<Driver, Failure>> {
    try {
      const result = await this.trackingDataSource.getDriverWithAddressByDate({
        id,
        dateFrom,
        dateTo,
      });
      return Ok(result);
    } catch (e) {
      if (e instanceof GraphQLError) return Err(GraphQLFailure(e));
      return Err(AnyFailure(e));
    }
  }
}
