import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { useState, useEffect } from "react";

const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firebaseAuth = firebase.auth;

firebase.initializeApp({
  apiKey: "AIzaSyAtp1w_0kkdeEwTugT2XAhDkLNXw3qlkko",
  authDomain: "umbrella-courier.firebaseapp.com",
  databaseURL: "https://umbrella-courier.firebaseapp.com",
  projectId: "umbrella-courier",
  storageBucket: "umbrella-courier.appspot.com",
  messagingSenderId: "693063096514",
  appId: "1:693063096514:web:ed58e5227adf71268f035b",
  measurementId: "G-JZTNJE1DH5"
});

export interface AuthState {
  status: "loading" | "in" | "out";
  token?: string;
  userDataParams?: UserDataParams,
}

export const AuthFirebaseProvider = ({ children }: any) => {
  const [authState, setAuthState] = useState<AuthState>({ status: "loading" });
  const callback = children;
  
  const setClaimsInState = (claims: any, token: string) => {
    const hasuraClaim = claims["https://hasura.io/jwt/claims"];
    let userDataParams: UserDataParams = {
      departments: JSON.parse(hasuraClaim["x-hasura-allowed-departments"]) as number[],
      // departments: [1],
      roles: hasuraClaim["x-hasura-allowed-roles"],
      email: claims['email'],
      name: claims['name'],
      nickname: claims['nickname'],
      sub: claims['sub'],
      userId: hasuraClaim["x-hasura-user-id"]
    }

    setAuthState({ status: "in", token: token, userDataParams: userDataParams });

    const defaultDepartment =
    userDataParams.departments.find((value) => {
        return value === parseInt(localStorage.getItem("choosed_department") ?? `${userDataParams.departments[0]}`);
      }) ?? userDataParams.departments[0];

    localStorage.setItem("choosed_department", `${defaultDepartment}`);
  };

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if(authState.token) {
          return;
        }

        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];

        if (hasuraClaim) {
          setClaimsInState(idTokenResult.claims, token);
        } else {
          // Check if refresh is required.
          const metadataRef = firebase.database().ref("metadata/" + user.uid + "/refreshTime");

          metadataRef.on("value", async (data) => {
            if (!data.exists) return;
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true);
            setClaimsInState(idTokenResult.claims, token);
          });
        }
      } else {
        setAuthState({ status: "out" });
      }
    });
  });


  return callback(authState);
};

export const signOut = async () => {
  try {
    await firebase.auth().signOut();
  } catch (error) {
    console.log(error);
  }
};

export const signInWithGoogle = async () => {
  try {
    await firebase.auth().signInWithPopup(googleProvider);
  } catch (error) {
    console.log(error);
  }
};
