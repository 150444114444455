import { Layout, Switch } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { observer } from "mobx-react-lite";
import React from "react";
import { DistrictsStore } from "../../mobx/DistrictsStore";
import { DistrictsTableView } from "./components/DistrictsTableView";

export const DistrictsPageView: React.FC<{ store: DistrictsStore }> = observer(
  ({ store }) => {
    store.init();
    return (
      <Layout>
        <Header
          style={{
            backgroundColor: "#1890ff",
          }}
        >
          <Switch
            style={{
              backgroundColor: store.districtTable.switchTableChecked
                ? "#0050b3"
                : "#096dd9",
            }}
            checkedChildren="District with drivers"
            unCheckedChildren="Driver with districts"
            checked={store.districtTable.switchTableChecked}
            onChange={(checked) => store.districtTable.switchTable(checked)}
          />
        </Header>
        <Content> 
          <DistrictsTableView store={store.districtTable} />
        </Content>
      </Layout>
    );
  }
);
