import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { OrderDrawerFormPicturesBlockProps } from "../../../../../interfaces/views/props/OrderDrawerFormPicturesBlockProps";

export const OrderDrawerFormPicturesBlock: React.FC<OrderDrawerFormPicturesBlockProps> =
  observer(({ pictures }) => {
    return (
      <div style={{ display: "flex" }}>
        {pictures.map((p) => (
          <a
            id={`${p.id}`}
            href={p.downloadURL}
            target="_blank"
            rel="noreferrer"
          >
            <div
              style={{
                ...styles,
                backgroundImage: `url(${getCroppedSizeLink(p.downloadURL)})`,
              }}
            ></div>
          </a>
        ))}
      </div>
    );
  });

const styles: CSSProperties = {
  width: "50px",
  height: "50px",
  backgroundSize: "cover",
  border: "1px solid",
};

function getCroppedSizeLink(link: string): string {
  const parts = link.split("?");
  const indexOfPoint = parts[0].lastIndexOf(".");
  parts[0] =
    parts[0].substr(0, indexOfPoint) +
    "_200x200" +
    parts[0].substr(indexOfPoint, parts[0].length - 1);
  return parts.join("?");
}
