import { observer } from "mobx-react-lite";
import { OrderTableViewProps } from "../../../interfaces/views/props/OrderTableViewProps";

import { Table, Input, Space } from "antd";

import Text from "antd/lib/typography/Text";

const { Search } = Input;
export const OrderTableView: React.FC<OrderTableViewProps> = observer(
  ({ table }) => {
    return (
      <div style={{ height: 400, width: "100%" }}>
        <Space style={{ padding: 8 }}>
          <Search
            style={{ width: 300 }}
            placeholder="Search by name, phone or short code"
            onSearch={(v) => table.onSearch(v)}
            allowClear
          />
          <Text type="secondary">
            Search in{" "}
            {table.currentStatus ? (
              <span>
                <strong>{table.currentStatus}</strong> status
              </span>
            ) : (
              <span>all statuses</span>
            )}
          </Text>
        </Space>
        <Table
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: table.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) =>
              table.onSelect(selectedRowKeys as number[], selectedRows),
          }}
          // fix later
          style={{ overflowX: "hidden" }}
          rowKey={(record: any) => record.id}
          dataSource={table.rows}
          pagination={table.tableParametres.pagination}
          columns={table.columns}
          loading={table.isLoading}
          size="small"
          onRow={(record, _rowIndex) => {
            return {
              onClick: (_event) => table.onRowClick(record),
            };
          }}
          onChange={(pagination, filters, sorters) => {
            table.onChange({ pagination, filters, sorters });
          }}
        />
      </div>
    );
  }
);
