import React from "react";
import { Layout, Input, DatePicker } from "antd";
import { DriverTileListView } from "./components/DriverTileListView";
import { TrackingMapView } from "./components/TrackingMapView";
import { DriverDrawerView } from "./components/DriverDrawer/DriverDrawerView";
import { observer } from "mobx-react-lite";
import { TrackingStore } from "../../mobx/TrackingStore";

import FormItem from "antd/lib/form/FormItem";

const { Search } = Input;
const { Content, Sider, Header } = Layout;

export const TrackingPageView: React.FC<{
  store: TrackingStore;
}> = observer(({ store }) => {
  store.init();
  return (
    <Layout>
      <Header
        style={{
          backgroundColor: "#1890ff",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Search placeholder={"Place"} style={{ width: 250 }} />
      </Header>
      <Layout>
        <Sider theme={"light"} width={390}>
          <FormItem style={{ padding: 8 }}>
            <DatePicker
              defaultValue={store.choosedDate}
              allowClear={false}
              onChange={(date) => store.onDateChange(date)}
            />
          </FormItem>
          <DriverTileListView store={store} />
        </Sider>
        <Content
          style={{ height: "100%", width: "100%", position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <DriverDrawerView drawerStore={store.drawer} />

            {/* {store.drawer.isVisible && (
              <Header
                style={{
                  position: "relative",
                  top: 0,
                  paddingLeft: "440px",
                  width: "100%",
                  height: 48,
                  display: "flex",
                  color: "black",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "lightgray",
                  fontWeight: "bold",
                  lineHeight: 0,
                }}
              >
                <p>Appointed</p>
                <p>Delivered</p>
                <p>Partially</p>
                <p>Undelivered</p>
              </Header>
            )} */}
          </div>
          <TrackingMapView store={store} />
        </Content>
      </Layout>
    </Layout>
  );
});
