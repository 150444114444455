import React from "react";
import { Button, Result } from "antd";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import { signOut } from "../core/providers/AuthFirebaseProvider";

export const PermissionDeniedView: React.FC = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Header className="login__header">Delivery Tool Kit</Header>
      <Layout>
        <Content>
          <Result
            status="500"
            title="Forbidden"
            subTitle="Sorry, you have no permission to access this page."
            extra={
              <Button type="primary" onClick={async () => await signOut()}>
                Log Out
              </Button>
            }
          />
        </Content>
      </Layout>
    </Layout>
  );
};
