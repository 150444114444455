import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import {
  AllDistrictsAndDriversWithDistricts,
  DistrictWithDrivers,
} from "../../interfaces/data/DistrictsRepositoryInterface";

export default class DistrictsDataSource {
  client: ApolloClient<NormalizedCacheObject>;

  constructor(client: ApolloClient<NormalizedCacheObject>) {
    this.client = client;
  }

  get departmentId(): number {
    return parseInt(localStorage.getItem("choosed_department") ?? "1");
  }

  async getDriverDistrictRelationList(): Promise<AllDistrictsAndDriversWithDistricts> {
    let result = await this.client.query({
      query: GET_DRIVERS_DISTRICT_LIST,
      variables: { departmentId: this.departmentId },
    });
    if (result.errors) {
      throw result.errors[0];
    }
    return { drivers: result.data.user, districts: result.data.district };
  }

  async getDistrictDriverRelationList(): Promise<Array<DistrictWithDrivers>> {
    let result = await this.client.query({
      query: GET_DISTRICTS_DRIVER_LIST,
      variables: { departmentId: this.departmentId },
    });
    if (result.errors) {
      throw result.errors[0];
    }
    return result.data.district;
  }

  async handleDeleteDriverDistrict(
    districtId: string,
    driverId: string
  ): Promise<void> {
    let result = await this.client.mutate({
      mutation: DELETE_DRIVER_DISTRICT,
      variables: { districtId, driverId },
    });
    if (result.errors) {
      throw result.errors[0];
    }
  }

  async deleteDriverDistrict(
    districtId: string,
    driverId: string
  ): Promise<void> {
    let result = await this.client.mutate({
      mutation: DELETE_DRIVER_DISTRICT,
      variables: { districtId, driverId },
    });
    if (result.errors) {
      throw result.errors[0];
    }
  }
  async insertDriverDistrict(
    districtId: string,
    driverId: string
  ): Promise<void> {
    let result = await this.client.mutate({
      mutation: INSERT_DRIVER_DISTRICT,
      variables: { districtId, driverId },
    });
    if (result.errors) {
      throw result.errors[0];
    }
  }

  async updateDistrictEnabled(districtId: string, enabled: boolean): Promise<void> {
    let result = await this.client.mutate({
      mutation: UPDATE_DISTRICT_ENABLED,
      variables: { districtId, enabled },
    });
    if (result.errors) {
      throw result.errors[0];
    }
  }

  async updateDistrictLoadFactor(
    loadFactor: number,
    districtId: string
  ): Promise<void> {
    let result = await this.client.mutate({
      mutation: UPDATE_DISTRICT_LOADFACTOR,
      variables: { loadFactor, districtId },
    });
    if (result.errors) {
      throw result.errors[0];
    }
  }

  async updateDistrictDeliveryDays(
    deliveryDays: number,
    districtId: string
  ): Promise<void> {
    let result = await this.client.mutate({
      mutation: UPDATE_DISTRICT_DELIVERYDAYS,
      variables: { deliveryDays, districtId },
    });
    if (result.errors) {
      throw result.errors[0];
    }
  }

  async updateDistrictOffDays(
    offDays: number[],
    districtId: string
  ): Promise<void> {
    let result = await this.client.mutate({
      mutation: UPDATE_DISTRICT_OFFDAYS,
      variables: { offDays, districtId },
    });
    if (result.errors) {
      throw result.errors[0];
    }
  }
}


const DELETE_DRIVER_DISTRICT = gql`
  mutation MyMutation($districtId: String!, $driverId: String!) {
    delete_driver_district(
      where: { driverId: { _eq: $driverId }, districtId: { _eq: $districtId } }
    ) {
      affected_rows
    }
  }
`;

const INSERT_DRIVER_DISTRICT = gql`
  mutation MyMutation($driverId: String!, $districtId: String!) {
    insert_driver_district_one(
      object: { districtId: $districtId, driverId: $driverId }
    ) {
      id
    }
  }
`;

const GET_DRIVERS_DISTRICT_LIST = gql`
  query getDriversDistrictList($departmentId: Int!) {
    user(
      where: {
        roles: { _contains: "driver" }
        blocked: { _eq: false }
        departments_users: { departmentId: { _eq: $departmentId } }
      }
    ) {
      id
      name
      driver_districts {
        id
        district {
          id
          name
          province {
            id
            name
          }
        }
      }
    }
    district(
      where: { departments_districts: { departmentId: { _eq: $departmentId } } }
    ) {
      id
      name
      province {
        id
        name
      }
    }
  }
`;

const UPDATE_DISTRICT_ENABLED = gql`
  mutation UpdateDistrictEnabled($districtId: String, $enabled: Boolean!) {
    update_district(
      where: { id: { _eq: $districtId } }
      _set: { enabled: $enabled }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_DISTRICT_LOADFACTOR = gql`
  mutation UpdateDistrictLoadFactor($loadFactor: Int, $districtId: String) {
    update_district(
      where: { id: { _eq: $districtId } }
      _set: { loadFactor: $loadFactor }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_DISTRICT_DELIVERYDAYS = gql`
  mutation UpdateDistrictDeliveryDays($deliveryDays: Int, $districtId: String) {
    update_district(
      where: { id: { _eq: $districtId } }
      _set: { deliveryDays: $deliveryDays }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_DISTRICT_OFFDAYS = gql`
  mutation UpdateDistrictOffDays($offDays: jsonb, $districtId: String) {
    update_district(
      where: { id: { _eq: $districtId } }
      _set: { offDays: $offDays }
    ) {
      affected_rows
    }
  }
`;

const GET_DISTRICTS_DRIVER_LIST = gql`
  query MyQuery($departmentId: Int!) {
    district(
      where: { departments_districts: { departmentId: { _eq: $departmentId } } }
    ) {
      loadFactor
      deliveryDays
      id
      enabled
      name
      offDays
      driver_districts {
        driverId
        driver {
          name
        }
      }
      province {
        id
        name
      }
    }
  }
`;
