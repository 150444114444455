export const getStatusColor = (status: string): string => {
  let bgColor = "#aaa";
  switch (status) {
    case "DriverAssigned":
      // no action
      break;
    case "DriverConfirmed":
      bgColor = "#F81";
      break;
    case "DriverOnTheWay":
      bgColor = "#33F";
      break;
    case "RescheduleOnDelivering":
    case "ReturnOnDelivering":
    case "Refuse":
      bgColor = "#E11";
      break;
    case "Delivered":
    case "CollectedMoney":
      bgColor = "#2A2";
      break;
  }
  return bgColor;
};
