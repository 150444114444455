import Card from "antd/lib/card";
import { observer } from "mobx-react-lite";

export const OrderChangePreloaderView: React.FC = observer(() => {
  return (
    <div style={{ padding: "4px 8px" }}>
      <Card
        loading={true}
        style={{ padding: 0 }}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span></span>
            <div>
              <span
                style={{ color: "#1890ffaa", fontWeight: 400, fontSize: 14 }}
              ></span>
              <span style={{ color: "#1890ff" }}></span>
            </div>
          </div>
        }
      >
        <div>
          <span>
            {[0, 0].map(() => (
              <div></div>
            ))}
          </span>
        </div>
      </Card>
    </div>
  );
});
