import { makeAutoObservable, runInAction } from 'mobx';
import { OrderRepository } from '../data/repository/order_repository';
import { OrderStoreMeta } from '../interfaces/stores/OrderStoreInterface';
import { OrderDrawer } from './OrderDrawer';
import { OrderTable } from './OrderTable';

export const OrderState = { loading: "LOADING", loaded: "LOADED", error: "ERROR" };

export class OrderStore {
    drawer!: OrderDrawer;
    table!: OrderTable;
    header = null;

    repository!: OrderRepository;

    meta: OrderStoreMeta = { statuses: [], drivers: [] };
    state: string = OrderState.loading;

    constructor() {
        makeAutoObservable(this, {
            drawer: false,
            table: false,
            header: false,
        });
    }

    async init(repository: OrderRepository, userDataParams: UserDataParams): Promise<void | null> {
        if (this.repository) return null;

        this.repository = repository;
        runInAction(() => this.state = OrderState.loading);
        const departments = await repository.getUserDepartments(userDataParams.departments);
        if (departments.error) {
            runInAction(() => this.state = OrderState.error);
            return;
        }

        window.UserData = {
            email: userDataParams.email,
            departments,
            roles: userDataParams.roles,
            name: userDataParams.name,
            nickname: userDataParams.nickname,
            sub: userDataParams.sub,
            userId: userDataParams.userId
          };

        const result = await this.repository.getMeta();
        if (result.error) {
            runInAction(() => this.state = OrderState.error);
            return;
        }

        this.meta.drivers = result.user!;

        await this.getStatuses();

        this.drawer = new OrderDrawer(this);
        this.table = new OrderTable(this);

        runInAction(() => this.state = OrderState.loaded);
    }

    async getStatuses() {
        const statuses = await this.repository.getStatuses();
        if(statuses.error) {
            this.state = OrderState.error;
        } else {
            runInAction(() => this.meta.statuses = statuses);
        }
    }
}
