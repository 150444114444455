import moment from "moment";

export function getStartTimeForTrackingFilter(date: moment.Moment): string {
  return moment(date.format("YYYY-MM-DD")).toISOString();
}
export function getEndTimeForTrackingFilter(date: moment.Moment): string {
  return moment(date.format("YYYY-MM-DD"))
    .set("h", 23)
    .set("m", 59)
    .set("s", 59)
    .set("millisecond", 999)
    .toISOString();
}
