export class Failure {
  message!: string;
  details?: any;
  constructor(message: string, details?: any) {
    this.message = message;
    this.details = details;
  }
}

export const GraphQLFailure = (err: any): Failure =>
  new Failure(err?.message ?? `${err}`, err);

export const AnyFailure = (err: any): Failure => new Failure(`${err}`, err);
