import { Select } from "antd";
import { CenteredSpin } from "./CenteredSpin";

const { Option } = Select;

export const HeaderSelect = () => {
  const departments = window.UserData.departments;
  const defaultDepartment = departments.find((value) => {
    return value.id === parseInt(localStorage.getItem("choosed_department")!);
  });

  return (
    <div style={{ position: "absolute", width: 150, right: 70 }}>
      {departments.length === 0 ? (
        <CenteredSpin style={{ width: 50, marginTop: 5 }} />
      ) : (
        <Select
          defaultValue={`${defaultDepartment?.name ?? "No departments"}`}
          style={{ width: "150px", display: "inline-block" }}
          onChange={(value) => {
            localStorage.setItem("choosed_department", value);
            new Promise((resolve) => setTimeout(resolve, 400)).then((_) =>
              window.location.reload()
            );
          }}
        >
          {departments.map((department, index) => (
            <Option key={index} value={department.id}>
              {department.name}
            </Option>
          ))}
        </Select>
      )}
    </div>
  );
};
