import { Avatar, List } from "antd";
import { observer } from "mobx-react-lite";
import { DriverTileProps } from "../../../interfaces/views/props/DriverTileProps";
import { Typography, Space } from "antd";
import { Driver } from "../../../interfaces/data/TrackingRepositoryInterface";
import moment from "moment";
import { FieldTimeOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const DriverTile = observer(({ driver, store }: DriverTileProps) => {
  return (
    <List.Item
      className="driver-tile"
      onClick={() => store.drawer.show(driver.id)}
    >
      <List.Item.Meta
        avatar={<Avatar size={48} icon={driver.name[0]} />}
        title={
          <DriverTileTitle
            driver={driver}
            delivered={driver.deliveredOrdersCount || 0}
            returned={driver.returnedOrdersCount || 0}
            reschedule={driver.rescheduledOrdersCount || 0}
          />
        }
        description={
          <div style={{ fontSize: ".85em" }}>
            <span>{driver.email}</span>
          </div>
        }
      />
    </List.Item>
  );
});

const DriverTileTitle: React.FC<DriverTileTitleProps> = ({
  delivered,
  returned,
  reschedule,
  driver,
}) => {
  const batteryLevel = driver.track_points[0]?.batteryLevel
    ? Math.round(driver.track_points[0].batteryLevel * 100)
    : -1;
  return (
    <div style={{ display: "flex", gap: 8, fontSize: "16px" }}>
      <Text>{driver.name}</Text>
      <Space
        style={{
          background: "rgba(24, 144, 255, .05)",
          padding: "0 8px",
          borderRadius: "4px",
        }}
        direction="horizontal"
      >
        <Text type="success">{delivered}</Text>
        <Text type="danger">{returned}</Text>
        <Text type="warning">{reschedule}</Text>
      </Space>
      <Text
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          color: "rgb(24, 144, 255)",
          fontWeight: 200,
        }}
      >
        <FieldTimeOutlined />
        {driver.track_points[0]
          ? moment(driver.track_points[0].time).format("HH:mm")
          : "<no geo>"}
      </Text>

      {batteryLevel !== -1 && (
        <div className="driver-battery-level">
          <span className="driver-battery-level--text">{batteryLevel}%</span>
          <div
            className="driver-battery-level--progress"
            style={{
              width: 0.35 * batteryLevel - 2,
              background: getBatteryColor(batteryLevel),
            }}
          ></div>
        </div>
      )}

      {/* <Progress
        width={40}
        type="circle"
        percent={
          driver.track_points[0] ? Math.round(driver.track_points[0].batteryLevel! * 100) : 0
        }
      /> */}
    </div>
  );
};

interface DriverTileTitleProps {
  delivered: number;
  returned: number;
  reschedule: number;
  driver: Driver;
}

function getBatteryColor(batteryLevel: number): string {
  let color = "red";
  if (batteryLevel >= 20 && batteryLevel < 40) {
    color = "orange";
  } else if (batteryLevel >= 40 && batteryLevel < 60) {
    color = "#ff4";
  } else if (batteryLevel >= 60) {
    color = "#2e2";
  }
  return color;
}
