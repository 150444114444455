import { List, Button, Modal, Space } from "antd";
import { observer } from "mobx-react-lite";
import { OrderDrawer } from "../../../../../mobx/OrderDrawer";

export const OrderDrawerFormPositionsModal: React.FC<{
  drawerStore: OrderDrawer;
}> = observer(({ drawerStore }) => (
  <Modal
    title="Add product to Order"
    visible={drawerStore.isVisibleModalAddPosition}
    onCancel={() => drawerStore.showOrHiddenModalAddPosition()}
  >
    <List
      dataSource={drawerStore.productsWithoutOrder}
      renderItem={(product) => (
        <List.Item key={product.name}>
          <Space>{product.name}</Space>
          <Space>
            <Button
              children="Add to Order"
              onClick={() => drawerStore.onAddToOrderPosition(product)}
            />
          </Space>
        </List.Item>
      )}
    />
  </Modal>
));
