import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  Select,
  List,
  Button,
  DatePicker,
  Input,
  Space,
  Spin,
  Tooltip,
  Modal,
  Tag,
} from "antd";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import { observer } from "mobx-react-lite";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { OrderDrawerViewProps } from "../../../../interfaces/views/props/OrderDrawerViewProps";
import { LocationSearchController } from "../../../../mobx/LocationSearch";
import { LocationSearchInputView } from "../../components/LocationSearchInputView";
import { OrderPDFSingleView } from "./components/OrderPDFView";
import "../../../../styles/drawer";

import {
  PrinterOutlined,
  CloseOutlined,
  EditOutlined,
  UpSquareOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
  CompassOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { layoutFormProps } from "./layout";

import { OrderDrawerFormPicturesBlock } from "./components/OrderDrawerFormPicturesBlock";
import { OrderDrawerFormPositionsModal } from "./components/OrderDrawerFormPositionsModal";
import { OrderExcelSingleView } from "./components/OrderExcelView";
import moment from "moment";
import { time_ranges } from "../../../../mobx/OrderDrawer";
import React from "react";
import { OrderChangesView } from "../../OrderChanges/OrderChangesView";

let inputController: LocationSearchController;
let last_id: number = 0;

const { Option } = Select;

export const OrderDrawerForm: React.FC<OrderDrawerViewProps> = observer(
  ({ drawerStore }) => {
    let form = drawerStore.form;

    const departments = window.UserData.departments;
    const defaultDepartment = departments.find((department) => {
      return department.id === form.order?.departmentId;
    });

    if (!inputController) {
      inputController = new LocationSearchController({
        onSelect: async (suggestion) => {
          let geocode = await geocodeByPlaceId(suggestion.placeId);
          let address = geocode[0].address_components;

          let country = address.find((v) => v.types[0] === "country");
          let city = address.find(
            (v) => v.types[0] === "administrative_area_level_1"
          );
          let district = address.find((v) => v.types[0] === "locality");
          drawerStore.setAddressPart("country", country!.long_name);
          drawerStore.setAddressPart("city", city!.long_name);
          drawerStore.setAddressPart(
            "district",
            district ? district.long_name : ""
          );
        },
      });
    }

    if (last_id !== form.order!.id || drawerStore.manualPriority) {
      inputController.searchText = drawerStore.addressPartsLine;
      drawerStore.manualPriority = false;
      last_id = form.order!.id;
    }

    const isManagerOrSeniorOperator =
      window.UserData.roles.includes("manager") ||
      window.UserData.roles.includes("senior-operator");

    const isManager = window.UserData.roles.includes("manager");

    const isUserIdExists =
      window.UserData.sub !== null && window.UserData.sub !== undefined;
    const isOrderIdExists =
      form.order?.id !== null && form.order?.id !== undefined;

    return (
      <>
        <Modal
          visible={drawerStore.isShowChangesModal}
          onCancel={() => drawerStore.closeChangesModal()}
          footer={null}
        >
          <OrderChangesView store={drawerStore.changesStore} />
        </Modal>
        <Form
          {...layoutFormProps}
          size="small"
          onFinish={(v) => {
            if (v.date.time && !v.order.plannedDate) {
              v.order.plannedDate = moment(form.order?.plannedDate);
            } else if (v.order.plannedDate && !v.date.time) {
              v.date.time = drawerStore.plannedDateTimeDefaultValue
                ?.split(" - ")
                .join(",");
            }

            return drawerStore.onFormSubmit(v);
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "0 10px",
              position: "relative",
            }}
          >
            <h2>{form.order!.shortCode}</h2>
            <PDFDownloadLink
              document={<OrderPDFSingleView form={form} />}
              fileName="order.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <Spin />
                ) : (
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PrinterOutlined />}
                  />
                )
              }
            </PDFDownloadLink>
            <OrderExcelSingleView form={form} />
            <Button
              icon={<HistoryOutlined />}
              type="primary"
              shape="circle"
              onClick={() => drawerStore.showChangesModal()}
            />
            <Button
              type="primary"
              htmlType="submit"
              children="Submit"
              style={{ position: "absolute", right: 45 }}
            />
            <Button
              style={{ position: "absolute", right: 0 }}
              onClick={() => drawerStore.store.table.resetSelection()}
              shape="circle"
              icon={<CloseOutlined />}
            />
          </div>
          {form.order!.resale && (
            <Tag color="gold" style={{ fontSize: "14px", marginBottom: "8px" }}>
              Reventa
            </Tag>
          )}
          <FormItem label="Driver" name={["order", "driverID"]}>
            <Select
              style={{ width: 200 }}
              defaultValue={form.order!.driver?.name || "---"}
            >
              {form.drivers!.map((driver) => (
                <Select.Option value={driver.id} key={driver.id}>
                  {driver.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="Status" name={["order", "statusName"]}>
            <Select defaultValue={form.order!.status!.name || "---"}>
              {form.order!.status!.status_transitions.map((transition) => (
                <Select.Option value={transition.next} key={transition.next}>
                  {transition.next}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem label="Planned date" shouldUpdate={true}>
            <Input.Group compact>
              <FormItem name={["order", "plannedDate"]}>
                <DatePicker
                  format={"YYYY-MM-DD"}
                  disabledDate={(_) => {
                    return (
                      moment().add(-1, "days") >=
                      moment(form.order!.createdAt, "YYYY-MM-DD")
                    );
                  }}
                  defaultValue={drawerStore.plannedDateDefaultValue}
                  allowClear={false}
                />
              </FormItem>
              <FormItem name={["date", "time"]}>
                <Select
                  placeholder="Choose time"
                  loading={drawerStore.isLoadingTimeRangeWithLoadFactor}
                  defaultValue={drawerStore.plannedDateTimeDefaultValue}
                >
                  {time_ranges.map((time, index) => {
                    var rangeWorkload: any;
                    // var workloadPercentage: number;
                    if (!drawerStore.isLoadingTimeRangeWithLoadFactor) {
                      rangeWorkload = drawerStore.workloads[index];

                      // workloadPercentage = rangeWorkload.workload / rangeWorkload.loadFactor;
                    }
                    return (
                      <Select.Option
                        key={index}
                        value={`${time[0]},${time[1]}`}
                      >
                        {`${time[0]} - ${time[1]}`}
                        {!drawerStore.isLoadingTimeRangeWithLoadFactor && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: 30,
                              width: 80,
                            }}
                          >
                            <span style={{ marginRight: 5, flex: 0 }}>
                              {/* {(workloadPercentage! < 0.5) && (<SmileTwoTone twoToneColor={["#1890ff", "white"]} />)}
                            {workloadPercentage! >= 0.5 && workloadPercentage! < 0.8 && (
                              <MehTwoTone twoToneColor={["orange", "white"]} />
                            )}
                            {workloadPercentage! >= 0.8 && <FrownTwoTone twoToneColor={["#a22", "white"]} />} */}
                            </span>
                            {Array.from(
                              { length: rangeWorkload.loadFactor },
                              () => 0
                            ).map((_, index) => {
                              var slotWorkload =
                                rangeWorkload.workload - index > 0
                                  ? rangeWorkload.workload - index
                                  : 0;

                              var opacity: number;

                              if (slotWorkload <= 0 || slotWorkload > 1) {
                                opacity = 1;
                              } else {
                                opacity = slotWorkload;
                              }

                              return (
                                <div
                                  key={index}
                                  style={{
                                    flex: rangeWorkload > 2 ? 1 : "none",
                                    height: 15,
                                    width: 15,
                                    flexShrink: 1,
                                    backgroundColor:
                                      rangeWorkload.workload > index
                                        ? `rgba(24, 144, 255, ${opacity})`
                                        : "lightgrey",
                                    borderRadius: 2,
                                    marginLeft: 3,
                                    boxShadow:
                                      "0 0 0 1px " +
                                      (rangeWorkload.workload > index
                                        ? "#1890ff"
                                        : "#666"),
                                  }}
                                />
                              );
                            })}
                          </div>
                        )}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Input.Group>
          </FormItem>
          <FormItem label="Fullname" name={["client", "fullname"]}>
            <Input
              placeholder="Fullname"
              defaultValue={form.order!.client?.fullname}
            />
          </FormItem>
          <FormItem label="Phone" name={["client", "phone"]}>
            {drawerStore.isPhonePressed ? (
              <Input
                placeholder="Phone"
                defaultValue={form.order!.client?.phone}
              />
            ) : (
              <Button
                onClick={() => {
                  if (isOrderIdExists && isUserIdExists) {
                    // let viewedAt = moment(Date.now()).toISOString();
                    let userId = window.UserData.userId;

                    drawerStore.toggleIsPhonePressed(userId!, form.order!.id);
                  }
                }}
                block
                type="primary"
              >
                Click to see phone
              </Button>
            )}
          </FormItem>
          {drawerStore.enterAddressManually ? (
            <>
              <FormItem
                label="Country"
                name={["address", "country"]}
                preserve={false}
                initialValue={drawerStore.addressParts.country}
              >
                <Input
                  placeholder="Country"
                  onChange={(e) =>
                    drawerStore.setAddressPart("country", e.target.value, true)
                  }
                />
              </FormItem>
              <FormItem
                label="City"
                name={["address", "city"]}
                preserve={false}
                initialValue={drawerStore.addressParts.city}
              >
                <Input
                  placeholder="City"
                  onChange={(e) =>
                    drawerStore.setAddressPart("city", e.target.value, true)
                  }
                />
              </FormItem>
              <FormItem
                label="District"
                preserve={false}
                initialValue={drawerStore.addressParts.district}
                name={["address", "district"]}
              >
                <Input
                  placeholder="District"
                  onChange={(e) =>
                    drawerStore.setAddressPart("district", e.target.value, true)
                  }
                />
              </FormItem>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Tooltip title="Use autocomplete" placement="left">
                  <UpSquareOutlined
                    onClick={() =>
                      drawerStore.switchToAutocompleteAddressInput()
                    }
                  />
                </Tooltip>
              </div>
            </>
          ) : (
            <FormItem label="District">
              <LocationSearchInputView
                options={{ types: ["(cities)"] }}
                controller={inputController}
                onManually={() => drawerStore.switchToManuallyAddressInput()}
                inputProps={{
                  suffix: (
                    <Tooltip title="Edit manually as parts" placement="left">
                      <EditOutlined
                        onClick={() =>
                          drawerStore.switchToManuallyAddressInput()
                        }
                      />
                    </Tooltip>
                  ),
                }}
              />
            </FormItem>
          )}
          <FormItem label="Street, house" name={["address", "street"]}>
            <TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Street, house"
              defaultValue={form.order!.client?.address?.street}
            />
          </FormItem>
          <FormItem label="Coordinates">
            <Input
              placeholder="Enter coordinates separated by commas..."
              onChange={(e) => drawerStore.onLatLngChange(e.target.value)}
              addonAfter={
                <CompassOutlined
                  onClick={() => {
                    const coordinates = [
                      form.order!.client.address.lat,
                      form.order!.client.address.lng,
                    ]
                      .filter((v) => v)
                      .join(", ");
                    const url = `https://www.google.com/maps?q=${coordinates}`;
                    window.open(url, "_blank");
                  }}
                />
              }
              defaultValue={[
                form.order!.client.address.lat,
                form.order!.client.address.lng,
              ]
                .filter((v) => v)
                .join(", ")}
            />
          </FormItem>
          <FormItem label="Address note" name={["address", "comments"]}>
            <TextArea
              autoSize={{ minRows: 2, maxRows: 6 }}
              defaultValue={form.order!.client?.address?.comments}
            />
          </FormItem>
          <FormItem label="Comment" name={["order", "comment"]}>
            <TextArea
              autoSize={{ minRows: 2, maxRows: 4 }}
              disabled={true}
              defaultValue={form.order?.comment}
            />
          </FormItem>
          {isManager ? (
            <>
              <FormItem
                label="Department"
                name={["order", "departmentId"]}
                preserve={false}
              >
                <Select
                  defaultValue={`${
                    defaultDepartment?.name ?? "No departments"
                  }`}
                  style={{ width: "150px", display: "inline-block" }}
                >
                  {departments.map((department, index) => (
                    <Option key={index} value={department.id}>
                      {department.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </>
          ) : (
            <></>
          )}

          <List
            dataSource={form.order!.positions}
            renderItem={(position) => {
              return (
                <List.Item key={position.productID}>
                  <Space>{position.product.name}</Space>
                  {position.totalPrice ? (
                    <Space>
                      {position.totalPrice}
                      {/* soles */}
                    </Space>
                  ) : (
                    <Space>---</Space>
                  )}
                  <Space>
                    {isManagerOrSeniorOperator ? (
                      <>
                        <Input
                          onChange={(e) => {
                            if (!e.target.value) {
                              e.target.valueAsNumber = 1;
                            }
                            return drawerStore.onChangeQuantity(
                              e.target.valueAsNumber,
                              position.id!
                            );
                          }}
                          required={true}
                          placeholder="Qnt."
                          type="number"
                          defaultValue={position.quantity}
                          min={1}
                        />
                        <DeleteOutlined
                          onClick={() => drawerStore.onDeletePosition(position)}
                        ></DeleteOutlined>
                      </>
                    ) : (
                      <p>{position.quantity} pcs.</p>
                    )}
                  </Space>
                </List.Item>
              );
            }}
          />
          <List.Item key="total">
            <Space>Total: </Space>
            {drawerStore.recalcIncorrectMessage && (
              <Space>
                <span style={{ color: "red" }}>
                  {drawerStore.recalcIncorrectMessage}
                </span>
              </Space>
            )}
            <Space>
              {drawerStore.totalPriceCalculating ? (
                <Spin />
              ) : (
                <b>
                  {form.order!.totalCents}
                  {/* soles */}
                </b>
              )}
            </Space>
          </List.Item>
          {isManagerOrSeniorOperator && (
            <Space>
              <PlusSquareOutlined
                onClick={() => drawerStore.showOrHiddenModalAddPosition()}
              />
            </Space>
          )}

          {form.order!.reasonRefusal && (
            <p>
              <b>Reason: {form.order!.reasonRefusal}</b>
            </p>
          )}
          <OrderDrawerFormPicturesBlock pictures={form.order!.order_photos!} />
        </Form>

        <OrderDrawerFormPositionsModal drawerStore={drawerStore} />
      </>
    );
  }
);
