import { List } from "antd";
import { observer } from "mobx-react-lite";
import { DriverTile } from "./DriverTile";
import { TrackingState, TrackingStore } from "../../../mobx/TrackingStore";
import { Driver } from "../../../interfaces/data/TrackingRepositoryInterface";
import { CenteredSpin } from "../../CenteredSpin";

export const DriverTileListView: React.FC<{
  store: TrackingStore;
}> = observer(({ store }) => {
  if (store.state === TrackingState.loading) {
    return <CenteredSpin style={{ height: 300 }} />;
  }
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={store.drivers}
        renderItem={(driver: Driver) => (
          <DriverTile store={store} driver={driver} />
        )}
      />
    </>
  );
});
