import { observer } from "mobx-react-lite";

import { Key } from "react/index";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import {
  Order,
  OrderInterface,
} from "../../../../../interfaces/data/OrderRepositoryInterface";
import React from "react";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    padding: "20pt 30pt",
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 16,
    padding: "10px 0",
  },
  row: {
    display: "table",
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 12,
    lineHeight: 1.2,
  },
  rowOfAddressFields: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 12,
  },
  addressField: {
    minWidth: 200,
  },
  centerAddressField: {
    margin: "0 10pt",
  },
  divider: {
    borderBottom: 1,
    marginTop: 10,
  },
  table: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
  tableFilledCell: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#5CA5E0",
    border: "1pt solid #3C648B",
    padding: 8,
    width: "32%",
    textAlign: "center",
  },
  tableCell: {
    display: "flex",
    justifyContent: "center",
    padding: 8,
    width: "32%",
    textAlign: "center",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 12,
    lineHeight: 1.2,
  },
  pageFooter: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1pt solid #000",
    marginTop: 20,
  },
  pageFooterTitle: {
    width: "50%",
    fontSize: 14,
    lineHeight: 1.2,
    minHeight: 200,
    padding: 10,
  },
  pageFooterTitleLeft: {
    borderRight: "1pt solid #000",
  },
});

const OrderPDFPage = observer(({ order }: { order: Order }) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.title}>
        <Text>Order &#8470;{order.shortCode}</Text>
      </View>
      <View style={styles.row}>
        <Text>Pick up from: {order.warehouse?.name}</Text>
      </View>
      <View style={styles.row}>
        <Text>Driver: {order.driver?.name}</Text>
      </View>
      <View style={styles.row}>
        <Text>Username: {order.driver?.email}</Text>
      </View>
      <View style={styles.row}>
        <Text>
          Planned date:{" "}
          {getOrderPlannedDateText({
            plannedDate: order.plannedDate!,
            plannedDateDuration: order.plannedDateDuration,
          })}
        </Text>
      </View>
      <View style={styles.divider}></View>
      <View style={styles.title}>
        <Text>Client</Text>
      </View>
      <View style={styles.row}>
        <Text>Fullname: {order.client?.fullname}</Text>
      </View>
      <View style={styles.rowOfAddressFields}>
        <Text style={styles.addressField}>
          District: {order.client?.address?.district}
        </Text>
        <Text style={{ ...styles.addressField, ...styles.centerAddressField }}>
          City: {order.client?.address?.city}
        </Text>
        <Text style={styles.addressField}>
          Country: {order.client?.address?.country}
        </Text>
      </View>
      <View style={styles.row}>
        <Text>Street: {order.client?.address?.street}</Text>
      </View>
      {/* <View style={styles.row}>
          <Text>Reference: por el colegio ventura</Text>
        </View> */}
      {/* <View style={styles.row}>
          <Text>Observation: llamar de 3 a 4 de la tarde que el cliente se encuentra en su casa</Text>
        </View> */}
      <View style={styles.row}>
        <Text>Comments: {order.client.address.comments}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableFilledCell}>
            <Text>PRODUCTO</Text>
          </View>
          <View style={styles.tableFilledCell}>
            <Text>CANTIDAD</Text>
          </View>
          <View style={styles.tableFilledCell}>
            <Text>PRECIO</Text>
          </View>
        </View>
        {order.positions.map((position) => (
          <React.Fragment key={position!.id as Key | null}>
            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>{position.product.name}</Text>
              <Text style={styles.tableCell}>{position.quantity}</Text>
              <Text style={styles.tableCell}>{position.totalPrice}</Text>
            </View>
          </React.Fragment>
        ))}
        <View style={styles.tableRow}>
          <Text style={styles.tableFilledCell}>TOTAL</Text>
          <Text style={styles.tableFilledCell}>{order.totalQuantity}</Text>
          <Text style={styles.tableFilledCell}>{order.totalCents}</Text>
        </View>
      </View>
      <View style={styles.pageFooter}>
        <View
          style={{ ...styles.pageFooterTitle, ...styles.pageFooterTitleLeft }}
        >
          <Text>Nota</Text>
        </View>
        <View style={styles.pageFooterTitle}>
          <Text>Verification stamp</Text>
        </View>
      </View>
    </Page>
  );
});

// Create Document Component
export const OrderPDFSingleView = observer(
  ({ form }: { form: OrderInterface }) => {
    return (
      <Document>
        <OrderPDFPage order={form.order!} />
      </Document>
    );
  }
);

export const OrderPDFMultiView = observer(
  ({ form }: { form: OrderInterface }) => {
    return (
      <Document>
        {form.orders!.map((order: Order) => (
          <React.Fragment key={order.id as Key | null}>
            <OrderPDFPage order={order} />
          </React.Fragment>
        ))}
      </Document>
    );
  }
);

const getOrderPlannedDateText = ({
  plannedDate,
  plannedDateDuration,
}: {
  plannedDate: string;
  plannedDateDuration?: number;
}): string => {
  let start = moment(plannedDate);
  let text = start.format("YYYY-MM-DD HH:mm");
  if (plannedDateDuration) {
    let end = start.add("minutes", plannedDateDuration);
    text += `-${end.format("HH:mm")}`;
  }
  return text;
};
