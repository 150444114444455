import { Menu, Spin } from 'antd'
import Layout, { Header } from 'antd/lib/layout/layout'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { Roles } from '../core/utils/roles'
import { DeliveryAdminProps } from '../interfaces/views/props/DeliveryAdminProps'
import { OrderState } from '../mobx/OrderStore'
import { DistrictsPageView } from './districts/DistrictsPageView'
import { HeaderSelect } from './HeaderSelect'
import LogoutButtonView from './LogoutButtonView'
import { OrderPageView } from './order/OrderPageView'
import { TrackingPageView } from './tracking/TrackingPageView'
import { UserEditPageView } from './user/UserEditPageView'

export const DeliveryAdmin: React.FC<DeliveryAdminProps> = observer(
  ({ orderStore, userStore, trackingStore, roles, districtsStore }) => {
    if (orderStore.state === OrderState.loading) {
      return (
        <div className="centered-spinner">
          <Spin />
        </div>
      )
    }

    var isObserver = roles.includes(Roles.observer)
    var isManager = roles.includes(Roles.manager)
    var isSeniorOperator = roles.includes(Roles.seniorOperator)
    return (
      <Layout style={{ height: '100%' }}>
        <Header style={{ height: '32px', lineHeight: '32px' }}>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[window.location.pathname]}>
            {isObserver ? (
              <Menu.Item key="/">
                <Link to="/">Tracking</Link>
              </Menu.Item>
            ) : (
              <Menu.Item key="/">
                <Link to="/">Orders</Link>
              </Menu.Item>
            )}
            {!isObserver && (
              <>
                <Menu.Item key="/tracking">
                  <Link to="/tracking">Tracking</Link>
                </Menu.Item>
                <Menu.Item key="/tracking-v2">
                  <a
                    href="https://erp.zaslogistica.com/delivery/tracking/track/2024-08-27"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tracking v2
                  </a>
                </Menu.Item>
              </>
            )}
            {isManager && (
              <Menu.Item key="/users">
                <Link to="/users">Users</Link>
              </Menu.Item>
            )}
            {(isManager || isSeniorOperator) && (
              <Menu.Item key="/districts">
                <Link to="/districts">Districts</Link>
              </Menu.Item>
            )}
            <HeaderSelect />
            <LogoutButtonView />
          </Menu>
        </Header>
        <Switch>
          {isObserver ? (
            <Route path="/">
              <TrackingPageView store={trackingStore} />
            </Route>
          ) : (
            <Route exact path="/">
              <OrderPageView orderStore={orderStore} />
            </Route>
          )}
          {!isObserver && (
            <Route path="/tracking">
              <TrackingPageView store={trackingStore} />
            </Route>
          )}
          {isManager && (
            <Route path="/users">
              <UserEditPageView store={userStore!} />
            </Route>
          )}
          {(isManager || isSeniorOperator) && (
            <Route path="/districts">
              <DistrictsPageView store={districtsStore} />
            </Route>
          )}
          <Route path="*">
            <p>Page not found</p>
          </Route>
        </Switch>
      </Layout>
    )
  },
)
