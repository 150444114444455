import { Card } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { UserViewsLog } from "../../../../interfaces/data/OrderRepositoryInterface";
import { RowView } from "./components/RowChangeView";

export const OrderUserViewsLogView: React.FC<{
  userViewsLog: UserViewsLog;
}> = observer(({ userViewsLog }) => {
  var createdAtDate = moment(userViewsLog.createdAt).format("DD, MMM");
  var createdAtTime = moment(userViewsLog.createdAt).format("HH:mm");
  var userName = userViewsLog.user.name;

  return (
    <div style={{ padding: "4px 8px" }}>
      <Card
        style={{ padding: 0 }}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{userName ?? "admin"}</span>
            <div>
              <span
                style={{ color: "#1890ffaa", fontWeight: 400, fontSize: 14 }}
              >
                {createdAtDate}{" "}
              </span>
              <span style={{ color: "#1890ff" }}>{createdAtTime}</span>
            </div>
          </div>
        }
      >
        <div style={{ padding: 2 }}>
          <span>{RowView("Viewed client phone number")}</span>
        </div>
      </Card>
    </div>
  );
});
