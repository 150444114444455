import { Button, Tag, Typography } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { UserTableColumnsInterface } from "../../interfaces/views/UserTableColumnsInterface";
import { UserInterface } from "../../interfaces/data/UserRepositoryInterface";

const { Text } = Typography;

export const getUserTableColumns = (
  onBanAction: (row: any) => void
): UserTableColumnsInterface[] => [
  {
    dataIndex: "email",
    key: "email",
    title: "Email",
  },
  {
    dataIndex: "name",
    key: "name",
    title: "Name",
    sorter: true,
    filters: [],
  },
  {
    dataIndex: "roles",
    key: "roles",
    title: "Roles",
    render: (roles) => (
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {roles.map((role: string) => (
          <Tag color="blue">{role}</Tag>
        ))}
      </div>
    ),
  },
  {
    dataIndex: "token",
    key: "token",
    title: "Token",
    render: (_value, r, _i) => {
      if ((r as UserInterface).firebaseMessagingToken !== null) {
        return <CheckOutlined style={{ color: "green" }} />;
      } else {
        return <CloseOutlined style={{ color: "red" }} />;
      }
    },
  },
  {
    dataIndex: "device",
    key: "device",
    title: "Device",
    ellipsis: true,
    render: (_value, r, _i) => {
      if ((r as UserInterface).login_logs!.length > 0) {
        return <Text>{(r as UserInterface).login_logs![0].device}</Text>;
      } else {
        return <CloseOutlined style={{ color: "red" }} />;
      }
    },
  },
  {
    dataIndex: "appVersion",
    key: "appVersion",
    title: "App Version",
    render: (_value, r, _i) => {
      if ((r as UserInterface).login_logs!.length > 0) {
        return <Text>{(r as UserInterface).login_logs![0].appVersion}</Text>;
      } else {
        return <CloseOutlined style={{ color: "red" }} />;
      }
    },
  },
  {
    dataIndex: "actions",
    key: "actions",
    title: "Actions",
    render: (_value, user: UserInterface, _i) => {
      if ((user as UserInterface).blocked) {
        return (
          <Button onClick={(_) => onBanAction(user)} type="primary">
            Unban
          </Button>
        );
      } else {
        return (
          <Button danger onClick={(_) => onBanAction(user)} type="primary">
            Ban
          </Button>
        );
      }
    },
  },
];
