import Button from "antd/lib/button";
import { observer } from "mobx-react-lite";
import {
  OrderChange,
  UserViewsLog,
} from "../../../interfaces/data/OrderRepositoryInterface";
import {
  OrderChangesState,
  OrderChangeStore,
} from "../../../mobx/OrderChanges";
import { order_change_formatters } from "./components/formatters";
import { OrderChangePreloaderView } from "./components/OrderChangePreloaderView";
import { OrderChangeView } from "./OrderChange/OrderChangeView";
import { OrderUserViewsLogView } from "./OrderChange/OrderUserViewsLogView";

export const OrderChangesView: React.FC<{ store: OrderChangeStore }> = observer(
  ({ store }) => {
    const wrapper = (child: React.ReactElement | string) => (
      <div>
        <h2 style={{ textAlign: "center" }}>History</h2>
        <div
          style={{
            minHeight: 430,
            maxHeight: "70vh",
            position: "relative",
            fontSize: 16,
            overflowY: "auto",
          }}
        >
          {child}
        </div>
      </div>
    );

    if (store.state === OrderChangesState.loading) {
      return wrapper(
        <>
          {[0, 0].map(() => (
            <OrderChangePreloaderView />
          ))}
        </>
      );
    } else if (store.state === OrderChangesState.error) {
      return wrapper(store.error!);
    } else if (store.totalCount === 0) {
      return wrapper(
        <div
          style={{
            display: "flex",
            position: "absolute",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No changes yet.
        </div>
      );
    }

    // Фильтруем только те изменения,
    // которые затрагивают хотя бы одно
    // поле, которое нужно отследить
    const filteredChangeList = store.changes.filter(
      (change: OrderChange) =>
        Object.keys(change.old).findIndex((v) =>
          order_change_formatters.includes(v)
        ) !== -1
    );

    const sorteredChangesAndUserViewsLogs = [
      ...filteredChangeList,
      ...store.userViewsLogs,
    ].sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    return wrapper(
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {/* {store.changes
          .filter(
            // Фильтруем только те изменения,
            // которые затрагивают хотя бы одно
            // поле, которое нужно отследить
            (change: OrderChange) =>
              Object.keys(change.old).findIndex((v) =>
                order_change_formatters.includes(v)
              ) !== -1
          )
          .map((change: OrderChange) => (
            <OrderChangeView change={change} />
          ))} */}
        {sorteredChangesAndUserViewsLogs.map((changeOrUserViewLog) => {
          if ("editor" in changeOrUserViewLog) {
            return (
              <OrderChangeView change={changeOrUserViewLog as OrderChange} />
            );
          }

          return (
            <OrderUserViewsLogView
              userViewsLog={changeOrUserViewLog as UserViewsLog}
            />
          );
        })}
        {/* {store.userViewsLogs.map((userViewsLog) => (
          <OrderUserViewsLogView userViewsLog={userViewsLog} />
        ))} */}
        {store.totalCount > store.changes.length && (
          <Button
            loading={store.state === OrderChangesState.loadingMore}
            type="link"
            onClick={() => store.fetchMore()}
            style={{ marginTop: 5 }}
          >
            Load more
            <p>↓</p>
          </Button>
        )}
      </div>
    );
  }
);
