import { Button, Table, Form, Space, Input, Select, Switch } from "antd";
import Search from "antd/lib/input/Search";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import Modal from "antd/lib/modal/Modal";
import { observer } from "mobx-react-lite";
import {
  NewUserModal,
  NewUserModalDocumentType,
  UserStore,
} from "../../mobx/UserStore";
import FormItem from "antd/lib/form/FormItem";
import { Option } from "antd/lib/mentions";
import { useEffect } from "react";


export const UserEditPageView = observer(({ store }: { store: UserStore }) => {
  useEffect(() => {
    store.init();
  }, [store]);

  return (
    <Layout>
      <Header className="header">
        <Button onClick={() => store.modal.show()}>Add user</Button>
        <Switch
          style={{
            marginLeft: "10px",
            backgroundColor: store.table.switchChecked ? "#0050b3" : "#096dd9",
          }}
          onChange={(value) => store.table.onSwitch(value)}
          checkedChildren="Inactive users"
          unCheckedChildren="Active users"
        />
      </Header>
      <Content>
        <Space style={{ padding: 8 }}>
          <Search
            style={{ width: 300 }}
            placeholder="Search by name or email"
            onSearch={(v) => store.table.onSearch(v)}
            allowClear
          />
        </Space>
        <Table
          columns={store.table.columns}
          dataSource={store.table.rows}
          pagination={store.table.tableParametres.pagination}
          loading={store.table.isLoading}
          onChange={(pagination, filters, sorters) => {
            store.table.onChange({ pagination, filters, sorters });
          }}
        />
      </Content>
      <NewUserModalView modal={store.modal} />
    </Layout>
  );
});

export const NewUserModalView = observer(
  ({ modal }: { modal: NewUserModal }) => {
    [modal.formComponent] = Form.useForm();

    return (
      <Modal
        visible={modal.visible}
        confirmLoading={modal.loading}
        onCancel={() => modal.hide()}
        onOk={() => modal.onSubmit()}
        okText="Create"
      >
        <h2>New user</h2>
        <Form
          form={modal.formComponent}
          onValuesChange={(_, values) => modal.onFormChange(values)}
        >
          <FormItem label="Name" name="name">
            <Input />
          </FormItem>
          <FormItem label="E-mail" name="email">
            <Input />
          </FormItem>
          <FormItem label="Password" name="password">
            <Input />
          </FormItem>
          <FormItem label="Department" name="departmentId">
            <Select defaultValue="---">
              {modal.store.meta.departments.map((department, index) => (
                <Option key={index.toString()} value={department.id.toString()}>
                  {department.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="Fullname" name={["metadata", "fullname"]}>
            <Input />
          </FormItem>
          <FormItem label="Document">
            <Select
              defaultValue={NewUserModalDocumentType.dni}
              onChange={(doc) => modal.setDocumentType(doc)}
            >
              {Object.keys(NewUserModalDocumentType).map((doc, index) => (
                <Option key={index.toString()} value={doc}>
                  {doc.toUpperCase()}
                </Option>
              ))}
            </Select>
          </FormItem>
          {modal.documentType === NewUserModalDocumentType.dni && (
            <FormItem label="DNI" name={["metadata", "dni"]}>
              <Input />
            </FormItem>
          )}
          {modal.documentType === NewUserModalDocumentType.ce && (
            <FormItem label="CE" name={["metadata", "ce"]}>
              <Input />
            </FormItem>
          )}
          {modal.documentType === NewUserModalDocumentType.ptp && (
            <FormItem label="PTP" name={["metadata", "ptp"]}>
              <Input />
            </FormItem>
          )}
          <FormItem label="Role" name="role">
            <Select defaultValue="---">
              <Option value="driver">Driver</Option>
              <Option value="operator">Operator</Option>
            </Select>
          </FormItem>
        </Form>
      </Modal>
    );
  }
);
