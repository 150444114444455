import React from "react";
import "antd/dist/antd.css";
import "./styles/app.css";
import "./styles/login.css";

import { LoginView } from "./views/LoginView";
import { PermissionDeniedView } from "./views/PermissionDeniedView";
import { OrderStore } from "./mobx/OrderStore";
import { OrderRepository } from "./data/repository/order_repository";

import { BrowserRouter as Router, Route } from "react-router-dom";
import { UserStore } from "./mobx/UserStore";
import TrackingRepository from "./data/repository/tracking_repository";
import { UserRepository } from "./data/repository/user_repository";
import { DeliveryAdmin } from "./views/DeliveryAdmin";
import { TrackingStore } from "./mobx/TrackingStore";
import { createClient } from "./data/repository/client";
import { CenteredSpin } from "./views/CenteredSpin";
import { DistrictsRepository } from "./data/repository/districts_repository";
import { DistrictsStore } from "./mobx/DistrictsStore";
import { getPrimaryRole } from "./core/utils/get_primary_role";
import { Roles } from "./core/utils/roles";
import { LogoutView } from "./views/LogoutView";
import {
  AuthFirebaseProvider,
  AuthState,
} from "./core/providers/AuthFirebaseProvider";

// const onRedirectCallback = (appState: AppState): void => {
//   window.history.replaceState(
//     {},
//     document.title,
//     appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
//   );
// };

const App: React.FC = () => (
  <Router>
    {/* <Auth0Provider
      domain="siswo.us.auth0.com"
      clientId="orVKyH2C01hUyuCaBoSog1KmpTLuYe1v"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience="https://hasura.zaslogistica.com/v1/graphql"
    > */}
    <Route path="/logout">
      <LogoutView />
    </Route>
    {/* <AuthProvider> */}

    {window.location.pathname !== "/logout" && (
      <AuthFirebaseProvider>
        {({ status, token, userDataParams }: AuthState) => {
          if (status === "loading") {
            return <CenteredSpin />;
          }

          if (status === "out") {
            return <LoginView />;
          }

          var roles = userDataParams!.roles;
          var primaryRole = getPrimaryRole(roles);

          if (primaryRole === Roles.anonymous) {
            return <PermissionDeniedView />;
          }
          if (token) {
            const client = createClient(token, primaryRole);
            let orderRepository = new OrderRepository(client);
            let trackingRepository = new TrackingRepository(client);
            let districtsRepository = new DistrictsRepository(client);
            const orderStore = new OrderStore();
            orderStore.init(orderRepository, userDataParams!);

            let userStore;
            if (roles!.includes("manager")) {
              let userRepository = new UserRepository(client);
              userStore = new UserStore(userRepository);
            }
            const drawerStore = new TrackingStore(trackingRepository);
            const districtsStore = new DistrictsStore(districtsRepository);
            return (
              <DeliveryAdmin
                orderStore={orderStore}
                userStore={userStore}
                roles={roles!}
                trackingStore={drawerStore}
                districtsStore={districtsStore}
              />
            );
          }
        }}
      </AuthFirebaseProvider>
    )}
    {/* </AuthProvider> */}
    {/* </Auth0Provider> */}
  </Router>
);

export default App;
