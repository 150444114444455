import { makeAutoObservable, runInAction, toJS } from "mobx";
import { SubscribeToOrdersForTableParams } from "../interfaces/data/OrderRepositoryProps";
import { ListOfRows } from "../interfaces/stores/OrderDrawerInterface";
import { OrderStoreMetaDrivers } from "../interfaces/stores/OrderStoreInterface";
import { OrderTableParametres } from "../interfaces/stores/OrderTableInterface";
import { OrderTableColumnsInterface } from "../interfaces/views/OrderTableColumnsInterface";
import { OrderStore } from "./OrderStore";
import { ORDER_TABLE_COLUMNS } from "./settings/ORDER_TABLE_COLUMNS";


const OrderTableState = {
  loading: "LOADING",
  loaded: "LOADED",
  error: "ERROR",
};

const getDateFilter = (key: string, dates: any[]) => [
  { [key]: { _gt: dates[0] } },
  { [key]: { _lt: dates[1] } }
];

export class OrderTable {
  state: string = OrderTableState.loading;
  error: string = "";
  rows: any[] = [];
  current_id: Nullable<number> = null;
  selectedRowKeys: number[] = [];
  expandButtonName: string = "Expand";
  hiddenLinkName: string = "header__link--hidden";
  currentStatus?: string = "New";
  store: OrderStore;

  tableParametres: OrderTableParametres = {
    pagination: {
      current: 1,
      defaultCurrent: 1,
      defaultPageSize: 15,
    },
    filters: {},
  };

  customFilters: any;
  columns: OrderTableColumnsInterface[] = ORDER_TABLE_COLUMNS;

  constructor(store: OrderStore) {
    makeAutoObservable(this, {
      columns: false,
      customFilters: false,
    });

    this.store = store;
    this.init();
  }

  async init(): Promise<void> {
    const meta = this.store.meta;
    const driverColumn = this.columns.find(
      (v: OrderTableColumnsInterface) => v.dataIndex === "driverName"
    );

    if (driverColumn) {
      driverColumn.filters = meta.drivers.map((driver: OrderStoreMetaDrivers) => ({
        text: driver.name,
        value: driver.id,
      }));
    }

    await this.updateRows();
  }

  async setStatus(statusName?: string): Promise<void> {
    if (this.currentStatus !== statusName) {
      this.store.getStatuses();

      runInAction(() => {
        this.selectedRowKeys = [];
        this.store.drawer.hide();
        this.tableParametres.pagination.current = 1;
        this.currentStatus = statusName;
      });

      await this.updateRows();
    }
  }

  handleExpandingOfStatuses(): void {
    runInAction(() => {
      if (this.hiddenLinkName.includes("header__link--hidden")) {
        this.hiddenLinkName = "header__link--show";
        this.expandButtonName = "Collapse";
      } else {
        this.hiddenLinkName = "header__link--hidden";
        this.expandButtonName = "Expand";
      }
    });
  }

  async updateRows(): Promise<void> {
    runInAction(() => {
      this.state = OrderTableState.loading;
    });

    if (this.currentStatus) {
      this.tableParametres.filters = {
        ...this.tableParametres.filters,
        status: [this.currentStatus],
      };
    } else {
      delete this.tableParametres.filters.status;
    }

    const params = this.getQueryParametres();

    await this.store.repository.subscribeToOrdersForTable({
      ...params,
      handlers: {
        onData: (response: any) =>
          runInAction(() => {
            this.state = OrderTableState.loaded;
            this.tableParametres.pagination.total = response.total;
            this.rows = response.orders;
          }),
        onError: (error: string) =>
          runInAction(() => {
            this.state = OrderTableState.error;
            this.error = error;
          }),
      },
    });
  }


  onRowClick(row: ListOfRows): void {
    if (this.selectedRowKeys[0] !== row.key) {
      this.selectedRowKeys = [row.key];
      this.store.drawer.showOrder(row.id);
    }
  }

  resetSelection(): void {
    if (this.selectedRowKeys.length > 0) {
      this.selectedRowKeys = [];
      this.store.drawer.hide();
    }
  }

  onSelect(selectedRowKeys: number[], selectedRows: ListOfRows[]): void {
    this.selectedRowKeys = selectedRowKeys;

    if (selectedRows.length > 1) {
      this.store.drawer.showMultiAction(selectedRows);
    } else if (selectedRows.length === 1) {
      this.store.drawer.showOrder(selectedRows[0].id);
    } else {
      this.store.drawer.hide();
    }
  }

  onChange({ pagination, filters, sorters }: OrderTableParametres): void {
    if (
      this.tableParametres.pagination !== pagination ||
      this.tableParametres.filters !== filters ||
      this.tableParametres.sorters !== sorters
    ) {
      this.tableParametres = { pagination, filters, sorters };
      this.updateRows();
    }
  }

  onSearch(searchText: string): void {
    const previousFilters = this.customFilters;

    if (searchText === "") {
      this.customFilters = null;
    } else {
      const like = `%${searchText}%`;
      this.customFilters = {
        _or: [
          {
            client: {
              _or: [
                { phone: { _like: like } },
                { fullname: { _ilike: like } },
                { address: { district: { _ilike: like } } },
              ],
            },
          },
          { shortCode: { _ilike: like } },
          { positions: { product: { name: { _ilike: like } } } },
        ],
      };
    }

    if (previousFilters !== this.customFilters) {
      this.updateRows();
    }
  }

  getQueryParametres(): SubscribeToOrdersForTableParams {
    const { pagination, filters: tableFilters, sorters: tableSorters } = toJS(this.tableParametres);
    const limit = pagination.pageSize || pagination.defaultPageSize || null;
    const offset = (limit ?? 0) * ((pagination.current ?? 1) - 1);

    const filters: any = {};
    const andFilters: any[] = [];

    if (tableFilters.driverName) {
      filters.driverID = { _in: tableFilters.driverName };
    }
    if (tableFilters.status) {
      filters.statusName = { _in: tableFilters.status };
    }
    if (tableFilters.resale) {
      filters.resale = { _in: tableFilters.resale };
    }

    const dateFields = ['plannedDate', 'updatedAt', 'deliveredDate', 'createdAt'];
    dateFields.forEach(field => {
      if (tableFilters[field]) {
        const dates = tableFilters[field][0];
        andFilters.push(...getDateFilter(field, dates));
      }
    });

    if (andFilters.length > 0) {
      filters._and = andFilters;
    }

    if (this.customFilters) {
      Object.assign(filters, this.customFilters);
    }

    let sorters: any = null;
    if (tableSorters?.order) {
      const sorterOrder = tableSorters.order.replace("end", "");
      sorters = {};
      if (tableSorters.field === "pickupFrom") {
        sorters.warehouse = { name: sorterOrder };
      } else if (tableSorters.field === "driverName") {
        sorters.driver = { name: sorterOrder };
      } else {
        sorters[tableSorters.field!] = sorterOrder;
      }
    }

    return {
      limit,
      offset,
      where: filters,
      sorters,
    };
  }

  get isLoading(): boolean {
    return this.state === OrderTableState.loading;
  }
}
