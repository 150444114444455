import React from "react";
import { Result } from "antd";
import Layout, { Content, Header } from "antd/lib/layout/layout";

import "../styles/login.css";
import { firebaseAuth } from "../core/providers/AuthFirebaseProvider";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebaseAuth.GoogleAuthProvider.PROVIDER_ID,
      firebaseAuth.EmailAuthProvider.PROVIDER_ID,
    ],
}

export const LoginView: React.FC = () => { return (
    <Layout style={{ height: "100vh" }}>
      <Header className="login__header">Delivery Tool Kit</Header>
      <Layout>
        <Content>
          <Result
            status="403"
            title="Authorization"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth()} />
              </>
            }
          />
        </Content>
      </Layout>
    </Layout>
  );
};
