import {
  Avatar,
  Card,
  // Col,
  Divider,
  Drawer,
  PageHeader,
  // Row,
  Space,
  Spin,
  Steps,
  Table,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import {
  DriverDrawerState,
  DriverDrawerStore,
} from "../../../../mobx/DriverDrawerStore";
// import {
//   CarryOutOutlined,
//   EnvironmentOutlined,
//   FieldTimeOutlined,
//   CloudOutlined,
// } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import {
  TrackingOrdersPositions,
  TrackingResultOrder,
} from "../../../../interfaces/data/TrackingRepositoryInterface";
import { getStatusColor } from "../../../../core/utils/get_status_color";

const { Step } = Steps;
const { Text } = Typography;

export const DriverDrawerView: React.FC<{
  drawerStore: DriverDrawerStore;
}> = observer(({ drawerStore }) => {
  return (
    <>
      <Drawer
        onClose={() => drawerStore.hide()}
        style={{ position: "absolute" }}
        title={
          drawerStore.state === DriverDrawerState.loaded && (
            <DriverDrawerHeader
              name={drawerStore.selectedDriver!.name}
              login={drawerStore.selectedDriver!.email}
            />
          )
        }
        placement="left"
        visible={drawerStore.isVisible}
        getContainer={false}
        mask={false}
        width={400}
      >
        {drawerStore.state === DriverDrawerState.loading && (
          <div className="centered-spinner">
            <Spin />
          </div>
        )}
        {drawerStore.state === DriverDrawerState.loaded && (
          <DriverDrawerBody drawerStore={drawerStore} />
        )}
      </Drawer>
    </>
  );
});

const DriverDrawerBody: React.FC<{ drawerStore: DriverDrawerStore }> = observer(
  ({ drawerStore }) => (
    <>
      {/* <DriverMetrics /> */}
      {/* <Divider /> */}
      {drawerStore.showedOrder ? (
        <DriverOrder
          order={drawerStore.showedOrder}
          onBack={() => drawerStore.hideOrder()}
        />
      ) : (
        <DriverWayPoints drawerStore={drawerStore} />
      )}
    </>
  )
);

const DriverDrawerHeader: React.FC<{ name: string; login: string }> = observer(
  ({ name, login }) => {
    return (
      <div style={{ display: "flex", padding: 15 }}>
        <Avatar size={64} icon={name[0]} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 15,
          }}
        >
          <strong>{name}</strong>
          <span>{login}</span>
        </div>
      </div>
    );
  }
);

const DriverWayPoints: React.FC<{ drawerStore: DriverDrawerStore }> = observer(
  ({ drawerStore }) => {
    return (
      <Steps direction="vertical">
        {/*// TODO(skyfet): type of order */}
        {drawerStore.selectedDriver!.orders.map(
          (el: TrackingResultOrder, i: number) => {
            return (
              <Step
                key={el.id}
                description={<DrawerWayPoint waypoint={el} />}
                icon={<DrawerWayPointIcon id={i + 1} status={el.statusName} />}
                onClick={() => drawerStore.showOrder(el.id)}
              />
            );
          }
        )}
      </Steps>
    );
  }
);

const DrawerWayPointIcon: React.FC<{ id: number; status: string }> = observer(
  ({ id, status }) => {
    let bgColor = getStatusColor(status);
    return (
      <span
        style={{
          background: bgColor,
          color: "white",
          borderRadius: "50%",
          height: 30,
          width: 30,
          fontSize: 15,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {id}
      </span>
    );
  }
);

const DrawerWayPoint: React.FC<{ waypoint: TrackingResultOrder }> = ({
  waypoint,
}) => {
  return (
    <Space direction="vertical" size={2}>
      <OrderPlannedDateText
        plannedDate={waypoint.plannedDate}
        plannedDateDuration={waypoint.plannedDateDuration}
      />
      <Text strong>{waypoint.shortCode || `Order #${waypoint.id}`} <Text type="secondary">({moment(waypoint.createdAt).format("YYYY-MM-DD HH:mm")})</Text></Text>
      <Text type="secondary">{waypoint.client.fullname}</Text>
    </Space>
  );
};

const DriverOrder: React.FC<{
  order: TrackingResultOrder;
  onBack?: () => void;
}> = observer(({ order, onBack }) => {
  return (
    <Card>
      <PageHeader
        onBack={onBack}
        title={order.shortCode || `Order #${order.id}`}
        style={{ padding: 0 }}
      />
      <OrderInfo order={order} />
      <Divider />
      <OrderPosition positions={order.positions} total={order.totalCents} />
    </Card>
  );
});

const OrderInfo: React.FC<{ order: TrackingResultOrder }> = observer(
  ({ order }) => {
    return (
      <Space direction="vertical" size={1}>
        <OrderPlannedDateText
          plannedDate={order.plannedDate}
          plannedDateDuration={order.plannedDateDuration}
        />
        <br />
        <Text type="secondary">{order.statusName}</Text>
        <br />
        <Text strong type="warning">
          {order.client.fullname}
        </Text>
        <Text type="secondary">Phone: {order.client.phone}</Text>
        {/* <Text type="secondary">Email: {order.client.email}</Text> */}
        <br />
        <>Direction: -</>
      </Space>
    );
  }
);

const OrderPlannedDateText: React.FC<{
  plannedDate: string;
  plannedDateDuration?: number;
}> = observer(({ plannedDate, plannedDateDuration }) => {
  return (
    <Text>
      {plannedDateDuration
        ? `R: ${moment(plannedDate).format("HH:mm")} - E: 
        ${moment(plannedDate)
          .add("minutes", plannedDateDuration)
          .format("HH:mm")}`
        : `R, E: ${moment(plannedDate).format("HH:mm")}`}
    </Text>
  );
});

const OrderPosition: React.FC<{
  positions: TrackingOrdersPositions[];
  total: number;
}> = observer(({ positions, total }) => {
  return (
    <>
      <Table
        columns={[
          { title: "Name", dataIndex: "name" },
          { title: "Quantity", dataIndex: "quantity" },
          { title: "Price", dataIndex: "totalPrice" },
        ]}
        dataSource={positions.map((p: TrackingOrdersPositions) => ({
          name: p.product.name,
          quantity: p.quantity,
          totalPrice: p.totalPrice,
        }))}
      />
      <Title level={3}>
        <Text type="warning">Total: </Text>
        {total ? total : "-"} 
        {/* SOLES */}
        {total ? "" : null}
      </Title>
    </>
  );
});
