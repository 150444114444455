import { gql, ApolloClient } from "@apollo/client";
import {
  GetUserMetaInterface,
  SubscribeToUsersProps,
  UserCreateInterface,
} from "../../interfaces/data/UserRepositoryInterface";
import { GraphQLError } from "graphql";

export class UserRepository {
  client: ApolloClient<any>;
  subscriptions: {
    userList?: ZenObservable.Subscription;
  } = {};

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  get departmentId(): number {
    return parseInt(localStorage.getItem("choosed_department") ?? "1");
  }

  async subscribeToUsers({
    limit,
    offset,
    where,
    sorters,
    handlers: { onData, onError, onComplete },
  }: SubscribeToUsersProps): Promise<void> {
    if (this.subscriptions.userList) {
      this.subscriptions.userList.unsubscribe();
    }

    where = {
      ...where,
      departments_users: { departmentId: { _eq: this.departmentId } },
    };

    this.subscriptions.userList = this.client
      .subscribe({
        query: GET_USERS,
        variables: {
          limit,
          offset,
          where: where,
          sorters: sorters || { id: "asc" },
        },
      })
      .subscribe({
        next: async (v) => {
          const totalOrError = await this.getTotalUsersCount({ where });

          if (totalOrError.error) {
            return onError!(totalOrError.error);
          }

          const total = totalOrError.user_aggregate.aggregate.count;

          return onData!({ users: v.data.user, total });
        },
        error: (e) => onError!(e),
        complete: () => onComplete && onComplete(),
      });
  }

  async getTotalUsersCount({ where }: any) {
    where = {
      ...where,
      departments_users: { departmentId: { _eq: this.departmentId } },
    };

    let result = await this.client.query({
      query: GET_TOTAL_USERS,
      variables: { "where": where },
    });

    if (result.errors) {
      return { error: result.errors[0] as GraphQLError };
    }

    return result.data;
  }

  async createUser({
    email,
    name,
    password,
    role,
    departmentId,
    metadata,
  }: UserCreateInterface) {
    try {
      let result = await this.client.mutate({
        mutation: CREATE_USER,
        variables: {
          email,
          name,
          password,
          departmentId: parseInt(departmentId || ""),
          roles: [role],
          metadata: metadata,
        },
      });
      if (result.errors) {
        return { error: (result.errors[0] as GraphQLError).message };
      }
      return result.data.createUser.userKey;
    } catch (e) {
      return { error: e };
    }
  }

  async getUserMeta(): Promise<GetUserMetaInterface> {
    let result = await this.client.query({
      query: GET_USER_META,
    });
    if (result.errors) {
      return { error: result.errors[0] as GraphQLError, user: [], department: [] };
    }
    return result.data;
  }

  /// fieldsObj содержит (а) для бана - { blocked: true },
  /// (б) для апдейта имя и роли - { role: "driver", name: "Petr" }
  async updateUserFields(user_id: string, fieldsObj: object): Promise<object> {
    let variables = { fields: fieldsObj, id: user_id };
    let result = await this.client.mutate({
      mutation: UPDATE_USER_FIELDS,
      variables: variables,
    });
    if (result.errors) {
      return { error: result.errors };
    }
    return result.data.update_user_by_pk;
  }

  blockOrUnblockUser(user_id: string, blocked: boolean): Promise<object> {
    return this.updateUserFields(user_id, { blocked: blocked });
  }
}

const GET_USERS = gql`
  query GetUsers(
    $limit: Int = 10
    $offset: Int = 0
    $where: user_bool_exp = {}
    $sorters: [user_order_by!]!
  ) {
    user(limit: $limit, offset: $offset, order_by: $sorters, where: $where) {
      blocked
      createdAt
      email
      id
      name
      roles
      firebaseMessagingToken
      login_logs(limit: 1, order_by: { createdAt: desc }) {
        device
        appVersion
      }
    }
  }
`;

const GET_USER_META = gql`
  query GetUserMeta {
    user(distinct_on: role) {
      role
    }
    department {
      id
      name
    }
  }
`;

const GET_TOTAL_USERS = gql`
  query GetTotalUsers($where: user_bool_exp = {}) {
    user_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $name: String!
    $password: String!
    $roles: [String!]!
    $departmentId: Int!
    $metadata: jsonb!
  ) {
    createUser(
      user: {
        email: $email
        name: $name
        password: $password
        roles: $roles
        departmentId: $departmentId
        metadata: $metadata
      }
    ) {
      userKey
    }
  }
`;

const UPDATE_USER_FIELDS = gql`
  mutation UpdateUserFields($fields: user_set_input!, $id: String!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $fields) {
      name
    }
  }
`;

