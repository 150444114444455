import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import {
  getEndTimeForTrackingFilter,
  getStartTimeForTrackingFilter,
} from "../core/utils/get_time_for_tracking_filter";
import {
  Driver,
  TrackingResultOrder,
} from "../interfaces/data/TrackingRepositoryInterface";
import { OrderTableColumnsInterface } from "../interfaces/views/OrderTableColumnsInterface";
import { TrackingStore } from "./TrackingStore";

export enum DriverDrawerState {
  loading,
  loaded,
  error,
}

export class DriverDrawerStore {
  store: TrackingStore;

  isVisible: boolean = false;
  selectedDriver?: Driver;
  showedOrder?: TrackingResultOrder | null;
  state: DriverDrawerState = DriverDrawerState.loading;

  columns: OrderTableColumnsInterface[] = [
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Quantity",
      dataIndex: "productQuantity",
      key: "productQuantity",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
  ];

  constructor(trackingStore: TrackingStore) {
    makeAutoObservable(this);
    this.store = trackingStore;
  }

  show(driverId: string): void {
    this.state = DriverDrawerState.loading;
    this.showedOrder = null;
    this.isVisible = true;

    this.store.repository
      .getDriversWithAddressByDate({
        id: driverId,
        dateFrom: getStartTimeForTrackingFilter(this.store.choosedDate),
        dateTo: getEndTimeForTrackingFilter(this.store.choosedDate),
      })
      .then((result) =>
        result.match<void>({
          ok: (driver: Driver) =>
            runInAction(() => {
              this.selectedDriver = driver;
              this.state = DriverDrawerState.loaded;
            }),
          err: (err) => message.error(err.message),
        })
      );
  }
  hide(): void {
    this.state = DriverDrawerState.loading;
    this.isVisible = false;
    this.selectedDriver = undefined;
  }

  showOrder(id: number): void {
    this.showedOrder = this.selectedDriver!.orders.find(
      (el: TrackingResultOrder) => el.id === id
    );
  }
  hideOrder(): void {
    this.showedOrder = null;
  }
}
