import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
import {
  getEndTimeForTrackingFilter,
  getStartTimeForTrackingFilter,
} from "../core/utils/get_time_for_tracking_filter";
import TrackingRepository from "../data/repository/tracking_repository";
import { Driver } from "../interfaces/data/TrackingRepositoryInterface";
import { DriverDrawerStore } from "./DriverDrawerStore";

export enum TrackingState {
  loading,
  loaded,
  error,
}

export class TrackingStore {
  drawer!: DriverDrawerStore;

  repository: TrackingRepository;
  drivers!: Driver[];
  choosedDate: moment.Moment = moment();

  state: TrackingState = TrackingState.loading;
  _initialized: boolean = false;

  constructor(repository: TrackingRepository) {
    makeAutoObservable(this);

    this.repository = repository;
  }

  init(): void {
    if (this._initialized) return;
    this._initialized = true;

    this.drawer = new DriverDrawerStore(this);
    this.getDrivers();
  }

  onDateChange(date: moment.Moment | null): void {
    if (date == null) return;
    this.choosedDate = date;
    this.getDrivers();
    this.drawer.hide();
  }

  private getDrivers() {
    this.state = TrackingState.loading;

    this.repository
      .getDriversWithLastTrackPoint({
        dateFrom: getStartTimeForTrackingFilter(this.choosedDate),
        dateTo: getEndTimeForTrackingFilter(this.choosedDate),
        onData: (drivers) =>
          runInAction(() => {
            this.drivers = drivers;
            this.state = TrackingState.loaded;
          }),
        onError: (err) => message.error(err.message),
      })
      .then(
        (result) => result.isErr() && message.error(result.unwrapErr().message)
      );
  }
}
