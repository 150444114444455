import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { OrderDrawer } from "../../../../../mobx/OrderDrawer";

interface OrderDrawerStatusConfirmModalInterface {
  drawerStore: OrderDrawer;
}

export const OrderDrawerStatusConfirmModal = observer(
  ({ drawerStore }: OrderDrawerStatusConfirmModalInterface) => {
    return (
      <Modal
        title="Alert"
        visible={drawerStore.isVisibleStatusAlertModal}
        onCancel={() =>  drawerStore.handleCancel()}
        onOk={() => drawerStore.handleOk()}
        cancelText="No"
        okText="Yes"
      >
        <p>{`Are you sure you want to set the ${
          drawerStore.formChanges?.order?.statusName ?? "unknown"
        } status for your order?`}</p>
      </Modal>
    );
  }
);
