import React from "react";
import { Table } from "antd";
import { DistrictsTableViewProps } from "../../../interfaces/views/props/DistrictsTableViewProps";
import { observer } from "mobx-react-lite";

export const DistrictsTableView: React.FC<DistrictsTableViewProps> = observer(
  ({ store }) => {
    return (
      <>
        <Table
          columns={store.columns}
          dataSource={store.tableData}
          loading={store.isLoading}
        />
      </>
    );
  }
);
