import React from "react";
import { Spin } from "antd";

export const CenteredSpin: React.FC<{
  style?: React.CSSProperties;
  size?: "small" | "default" | "large";
}> = ({ style, size }) => (
  <div className="centered-spinner" style={style}>
    <Spin size={size} />
  </div>
);
