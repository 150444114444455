import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { OrderInterface } from "../../../../../interfaces/data/OrderRepositoryInterface";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import ExceptionOutlined from "@ant-design/icons/lib/icons/ExceptionOutlined";

export const OrderExcelSingleView = observer(
  ({ form }: { form: OrderInterface }) => {
    const order = form.order!;
    const orders = [];
    const newOrder = {
      Order: order.shortCode,
      Driver: order.driver?.name,
      Username: order.driver?.email,
      PlannedDate: getOrderPlannedDateText({
        plannedDate: order.plannedDate!,
        plannedDateDuration: order.plannedDateDuration,
      }),
      ClientFullname: order.client.fullname,
      District: order.client.address.district,
      Street: order.client.address.street,
      City: order.client.address.city,
      Country: order.client.address.country,
      Comments: order.client.address.comments,
      Producto: order.positions
        .map(
          (position) =>
            `Nombre: ${position.product.name}\nCantidat: ${
              position.quantity
            }\nPrecio: ${
              position.totalPrice !== null && position.totalPrice !== undefined
                ? position.totalPrice
                : "-"
            }`
        )
        .join("\n\n"),
      TotalCents: order.totalCents,
    };

    orders.push(newOrder);

    return <OrderExcelView orders={orders} />;
  }
);

export const OrderExcelMultiView = observer(
  ({ form }: { form: OrderInterface }) => {
    const start = moment(Date.now());
    const fileName = `Multi_Order ${start.format("HH-mm")}`;
    const orders: any[] = [];

    form.orders!.forEach((order) => {
      const newOrder = {
        Order: order.shortCode,
        Driver: order.driver?.name,
        Username: order.driver?.email,
        PlannedDate: getOrderPlannedDateText({
          plannedDate: order.plannedDate!,
          plannedDateDuration: order.plannedDateDuration,
        }),
        ClientFullname: order.client.fullname,
        District: order.client.address.district,
        Street: order.client.address.street,
        City: order.client.address.city,
        Country: order.client.address.country,
        Comments: order.client.address.comments,
        Producto: order.positions
          .map(
            (position) =>
              `Nombre: ${position.product.name}\nCantidat: ${
                position.quantity
              }\nPrecio: ${
                position.totalPrice != null || position.totalPrice !== undefined
                  ? position.totalPrice
                  : "no precio"
              }`
          )
          .join("\n\n"),
        TotalCents: order.totalCents,
      };
      orders.push(newOrder);
    });
    return <ExportCSV csvData={orders} fileName={fileName} />;
  }
);

export const OrderExcelView = observer(({ orders }: { orders: any }) => {
  const start = moment(Date.now());
  const fileName = `Singe-Order ${start.format("HH-mm")}`;
  return <ExportCSV csvData={orders} fileName={fileName} />;
});

export const ExportCSV = observer(
  ({ csvData, fileName }: { csvData: any; fileName: any }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData: any, fileName: any) => {
      const workSheet = XLSX.utils.json_to_sheet(csvData);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
      <Button
        icon={<ExceptionOutlined />}
        type="primary"
        shape="circle"
        onClick={(e) => exportToCSV(csvData, fileName)}
      />
    );
  }
);

const getOrderPlannedDateText = ({
  plannedDate,
  plannedDateDuration,
}: {
  plannedDate: string;
  plannedDateDuration?: number;
}): string => {
  let start = moment(plannedDate);
  let text = start.format("YYYY-MM-DD HH:mm");
  if (plannedDateDuration) {
    let end = start.add("minutes", plannedDateDuration);
    text += `-${end.format("HH:mm")}`;
  }
  return text;
};
