import { makeAutoObservable } from "mobx";
import { OrderChange, UserViewsLog, } from "../interfaces/data/OrderRepositoryInterface";
import { OrderDrawer } from "./OrderDrawer";

export enum OrderChangesState {
  loading,
  error,
  loaded,
  loadingMore,
}

export class OrderChangeStore {
  constructor(store: OrderDrawer) {
    makeAutoObservable(this, { store: false, orderId: false });

    this.store = store;
  }

  state: OrderChangesState = OrderChangesState.loading;
  changes: OrderChange[] = [];
  totalCount: number = 0;
  store: OrderDrawer;
  error?: string;
  orderId?: number;
  userViewsLogs: UserViewsLog[] = [];

  async getChanges(orderId: number, offset = 0) {
    if (offset) this.state = OrderChangesState.loadingMore;
    else this.state = OrderChangesState.loading;

    let response = await this.store.store.repository.getOrderChangeHistory(
      orderId,
      offset
    );

    if (response.error) {
      this.error = response.error.message;
      this.state = OrderChangesState.error;
      return;
    }

    // Сохраняем id текущего заказа, чтобы использовать
    // в методе fetchMore
    this.orderId = orderId;
    if (offset === 0) {
      this.changes = response.data!.changes
      this.userViewsLogs = response.data!.userViewsLogs;
    } else {
      this.changes = [...this.changes, ...response.data!.changes]
      this.userViewsLogs = [...this.userViewsLogs, ...response.data!.userViewsLogs]
    }
    this.totalCount = response.data!.totalCount;
    this.state = OrderChangesState.loaded;
  }

  fetchMore() {
    this.state = OrderChangesState.loadingMore;
    return this.getChanges(this.orderId!, this.changes.length);
  }
}
