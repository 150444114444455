import React, { ReactElement } from "react";
import GoogleMapReact from "google-map-react";
import { observer } from "mobx-react-lite";

import { EnvironmentTwoTone, CarTwoTone } from "@ant-design/icons";
import { TrackingStore } from "../../../mobx/TrackingStore";
import { getStatusColor } from "../../../core/utils/get_status_color";

interface GoogleMapComponent<Props = {}>
  extends React.FC<{ lat: number; lng: number } & Props> {}

const Marker: GoogleMapComponent<{
  icon?: ReactElement<any>;
  containerStyle?: React.CSSProperties;
  size?: number;
  padding?: number;
}> = ({ icon, containerStyle = {}, size = 30, padding = size / 3 }) => {
  if (!icon) icon = <EnvironmentTwoTone />;
  return (
    <div
      style={{
        fontSize: size,
        transition: "all .2s",
        transform: "translate(-50%, -50%)",
        background: "#fff",
        width: size + padding * 2,
        height: size + padding * 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: size / 2 + padding,
        boxShadow: "0 0 5px #0004",
        ...containerStyle,
      }}
    >
      {icon}
    </div>
  );
};

export const TrackingMapView: React.FC<{ store: TrackingStore }> = observer(
  ({ store }) => {
    return (
      <GoogleMapReact
        defaultCenter={{ lat: -12.04318, lng: -77.02824 }}
        defaultZoom={12}
      >
        {store.drawer.selectedDriver &&
          store.drawer.selectedDriver.orders.map(
            (order, i) =>
              order.client.address.lat &&
              order.client.address.lng && (
                <Marker
                  key={order.id}
                  lat={order.client.address.lat!}
                  lng={order.client.address.lng!}
                  size={20}
                  containerStyle={{
                    background: getStatusColor(order.statusName),
                    color: "white",
                  }}
                  icon={<span>{i + 1}</span>}
                />
              )
          )}

        {store.drivers?.map(
          (driver) =>
            driver.track_points[0] && (
              <Marker
                key={driver.id}
                lat={driver.track_points[0].lat}
                lng={driver.track_points[0].lng}
                size={
                  store.drawer.selectedDriver &&
                  store.drawer.selectedDriver.id !== driver.id
                    ? 20
                    : 25
                }
                icon={
                  <CarTwoTone
                    style={{
                      opacity:
                        store.drawer.selectedDriver &&
                        store.drawer.selectedDriver.id !== driver.id
                          ? 0.5
                          : 1,
                    }}
                  />
                }
              />
            )
        )}
      </GoogleMapReact>
    );
  }
);
