import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";

const hasSubscriptionOperation = ({
  query: { definitions },
}: {
  query: any;
}) => {
  return definitions.some(
    ({ kind, operation }: { kind: string; operation: string }) =>
      kind === "OperationDefinition" && operation === "subscription"
  );
};

export const createClient = (token: string, primaryRole: string) => {
  let params = {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Hasura-Role': primaryRole,
    },
  };

  const link = ApolloLink.split(
    hasSubscriptionOperation,
    new WebSocketLink({
      uri: "wss://hasura.zaslogistica.com/v1/graphql",
      options: {
        reconnect: true,
        connectionParams: params,
      },
    }),
    new HttpLink({
      uri: "https://hasura.zaslogistica.com/v1/graphql",
      headers: params.headers,
    })
  );

  const options: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  return new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    defaultOptions: options,
  });
};
