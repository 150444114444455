export class Flow {
  actions: HashedFlowedAction = [];
  lastIndex: number = 0;
  onFinish?: () => void;

  addToFlow(promise: PromiseFunction, action: ActionFunction): void {
    const newIndex = ++this.lastIndex;
    const newFlowedAction = new FlowedAction(promise, action, newIndex);
    this.actions[newIndex] = newFlowedAction;
    try {
      this.next(newIndex);
    } catch (e) {
      console.error("Flow Error: ", e);
    }
  }

  next(index: number): void {
    let currentAction = this.actions[index];
    currentAction
      .promise()
      .then((result) => {
        if (this.lastIndex <= index) {
          currentAction.action(result);
        }
        this.remove(index);
      })
      .catch((_) => this.remove(index));
  }

  remove(index: number): void {
    this.actions = this.actions.filter((action) => action.index !== index);
    if (this.actions.length === 0 && this.onFinish) {
      this.onFinish();
    }
  }
}

interface HashedFlowedAction extends Array<FlowedAction> {
  [id: number]: FlowedAction;
}

type PromiseFunction = {
  (...args: any): Promise<any>;
};

type ActionFunction = {
  (promiseResult: any): void;
};

class FlowedAction {
  promise!: PromiseFunction;
  action!: ActionFunction;
  index!: number;

  constructor(promise: PromiseFunction, action: ActionFunction, index: number) {
    this.promise = promise;
    this.action = action;
    this.index = index;
  }
}
