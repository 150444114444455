import { Roles } from "./roles";

/// Returns the highest user role for admin app
export function getPrimaryRole(roles: string[]): string {
  if (roles.includes(Roles.manager)) {
    return Roles.manager;
  } else if (roles.includes(Roles.seniorOperator)) {
    return Roles.seniorOperator;
  } else if (roles.includes(Roles.operator)) {
    return Roles.operator;
  } else if (roles.includes(Roles.observer)) {
    return Roles.observer;
  } else return Roles.anonymous;
}
