import { AutoComplete, Input, Spin, Tag } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { DistrictsTable } from "../../../mobx/DistrictsStore";
import { PlusOutlined } from "@ant-design/icons";
import { District } from "../../../interfaces/data/DistrictsRepositoryInterface";

export const DistrictsInputView: React.FC<{
  store: DistrictsTable;
  driverId: string;
}> = observer(({ store, driverId }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: 6 }}>
      {store.rowStates[driverId].districts.map((district: District) => {
        return (
          <Tag
            key={district.id}
            color={"blue"}
            closable
            onClose={function (e) {
              store.handleClose(district, driverId);
              e.preventDefault();
            }}
            style={{ fontSize: 14, margin: 0 }}
          >
            {district.name} ({district.province.name})
          </Tag>
        );
      })}
      <div>
        {store.rowStates[driverId].inputVisible ? (
          <div className="tag-autocomplete-container">
            <AutoComplete
              allowClear
              style={{ height: 22 }}
              children={<Input className="tag-autocomplete-input" />}
              placeholder="Enter the district"
              options={store.rowStates[driverId].unusedDistricts.map(
                (district: District) => ({
                  label: `${district.name} (${district.province.name})`,
                  value: district.id,
                })
              )}
              filterOption={function (inputValue, option) {
                return (
                  option?.label
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(inputValue.toLowerCase()) ?? false
                );
              }}
              onChange={(value) => store.handleInputChange(value, driverId)}
              onSelect={
                store.rowStates[driverId].isLoading
                  ? undefined
                  : (v: string) => store.handleInputConfirm(v, driverId)
              }
              value={store.rowStates[driverId].inputValue}
            />
            {
              store.rowStates[driverId].isLoading && (
                <Spin
                  size="small"
                  style={{ position: "relative", top: 2, left: 4 }}
                />
              )
              // : (
              //   <Button
              //     color="primary"
              //     className="tag-autocomplete-plus-button"
              //     onClick={() => store.handleInputConfirm(driverId)}
              //     icon={<PlusOutlined style={{ fontSize: 17 }} />}
              //   />
              // )
            }
          </div>
        ) : (
          <Tag
            onClick={() => store.showInput(driverId)}
            className="site-tag-plus"
          >
            <PlusOutlined /> New Tag
          </Tag>
        )}
      </div>
    </div>
  );
});
