import { makeAutoObservable } from 'mobx';
import { LocationSearchControllerPropsInterface } from '../interfaces/stores/props/LocationSearchControllerPropsInterface';


export class LocationSearchController {
    searchText: string = '';
    // TODO: прописать тип suggestion
    suggestion: any = null;
    onSelectAction: (s: any) => Promise<void>;
    onChangeAction?: (value: string) => Promise<void>;
    dropdownHidden: boolean = true;

    constructor({ onSelect, onChange }: LocationSearchControllerPropsInterface) {
        makeAutoObservable(this);
        this.onSelectAction = onSelect;
        this.onChangeAction = onChange;
    }

    onSelect(s: any): void {
        if (!s || s === '') {
            this.suggestion = null;
            return;
        }

        this.searchText = s.description || s;
        this.suggestion = s;

        this.dropdownHidden = true;
        if (typeof this.onSelectAction === 'function') {
            this.onSelectAction(s);
        }
    }

    onChange(value: string): void {
        // if value is empty hide dropdown, else show
        this.dropdownHidden = value === "";

        this.searchText = value;

        if (typeof this.onChangeAction === 'function') {
            this.onChangeAction(value);
        }
    }
}
