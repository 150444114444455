import React from "react";
import { Header } from "antd/lib/layout/layout";

import { observer } from "mobx-react-lite";
import { OrderHeaderViewProps } from "../../../interfaces/views/props/OrderHeaderViewProps";
import { HeaderStatusesViewProps } from "../../../interfaces/views/props/HeaderStatusesViewProps";

export const OrderHeaderView: React.FC<OrderHeaderViewProps> = observer(
  ({ order }) => {
    return (
      <Header className="header">
        <HeaderStatusesView order={order} />
      </Header>
    );
  }
);

const HeaderStatusesView: React.FC<HeaderStatusesViewProps> = observer(
  ({ order }) => {
    return (
      <>
        <div className="header__statuses">
          <div
            className={
              "header__link " + (!order.table.currentStatus ? "active" : "")
            }
            onClick={() => order.table.setStatus()}
          >
            All
          </div>
          {[
            ...[...order.meta.statuses]
              .sort((a, b) => 
                {
                  if (a.orders_aggregate.aggregate.count !== 0 && b.orders_aggregate.aggregate.count === 0) return -1;
                  if (a.orders_aggregate.aggregate.count === 0 && b.orders_aggregate.aggregate.count !== 0) return 1;
                  return 0;
                }
              )
              .map((s) => {
                var className = "header__link " + 
                  (s.name === order.table.currentStatus ? "active" : "");

                if(s.orders_aggregate.aggregate.count === 0) {
                  className += " " + order.table.hiddenLinkName;
                }

                return (
                  <div
                    className={className}
                    key={s.name}
                    onClick={() => order.table.setStatus(s.name)}
                  >
                    {s.name} ({s.orders_aggregate.aggregate.count})
                  </div>
                );
              }),
          ]}
          <div
            className="header__link header__link--expand"
            onClick={() => order.table.handleExpandingOfStatuses()}
          >
            {order.table.expandButtonName}
          </div>
        </div>
      </>
    );
  }
);
