import { InputNumber, message, Select, Tooltip } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import { Failure } from "../core/failures/failures";
import { DistrictsRepository } from "../data/repository/districts_repository";
import {
  AllDistrictsAndDriversWithDistricts,
  District,
  DistrictWithDrivers,
  DriverWithDistricts,
} from "../interfaces/data/DistrictsRepositoryInterface";
import { DistrictsInputView } from "../views/districts/components/DistrictsInputView";
import { DriverWithDistrictsView } from "../views/districts/components/DriverWithDistrictsView";
import { DistrictSwitch } from "../views/districts/components/DistrictSwitchView";
import QuestionCircleTwoTone from "@ant-design/icons/lib/icons/QuestionCircleTwoTone";

const _offDaysOptions = [
  { label: "Mon", value: 1 },
  { label: "Tue", value: 2 },
  { label: "Wed", value: 3 },
  { label: "Thu", value: 4 },
  { label: "Fri", value: 5 },
  { label: "Sat", value: 6 },
  { label: "Sun", value: 7 },
];

const _limitedUsers = [
  //* Peru | Colombia
  "anton.lima@umbrella-trade.com",
  "vladimir.riazanov@umbrella-trade.com",
  "omar.velasquez@umbrella-trade.com",
  "lima.andrew.chicchon@umbrella-trade.com",

  //* Chile
  "pavel.e@umbrella-trade.com",
  "natalia.chile@umbrella-trade.com",

  //* Developers
  "dydynovdima6@gmail.com",
];

export enum DistrictsState {
  loading,
  loaded,
  error,
}

export class DistrictsStore {
  repository: DistrictsRepository;
  state: DistrictsState = DistrictsState.loaded;
  _initialized: boolean = false;
  districtTable: DistrictsTable;

  constructor(repository: DistrictsRepository) {
    makeAutoObservable(this);
    this.repository = repository;
    this.districtTable = new DistrictsTable(this);
  }

  async init() {
    if (this._initialized) return;
    this._initialized = true;

    await this.districtTable.getDrivers();
  }
}

export class DistrictsTable {
  switchTableChecked: boolean = false;

  columns: any;

  tableData!: any[];
  districts!: District[];
  drivers!: any[];

  store: DistrictsStore;
  isLoading: boolean = true;

  rowStates: any = {};
  secondTableRowStates: any = {};

  constructor(store: DistrictsStore) {
    makeAutoObservable(this);
    this.store = store;
  }

  switchTable = (checked: boolean): void => {
    this.switchTableChecked = checked;

    this.switchTableChecked ? this.getDistricts() : this.getDrivers();
  };

  showInput = (driverId: string): void => {
    let inputVisible = this.rowStates[driverId].inputVisible;

    this.rowStates[driverId].inputVisible = !inputVisible;
  };

  handleInputConfirm = async (districtId: string, driverId: string) => {
    let districts = this.rowStates[driverId].districts;
    let district = this.districts.find((d: District) => d.id === districtId);
    this.rowStates[
      driverId
    ].inputValue = `${district?.name} (${district?.province.name})`;

    if (!districtId) return;
    if (
      districts.findIndex(
        (district: District) => district.id === districtId
      ) !== -1
    ) {
      message.info("This district already exists");
      return;
    }
    runInAction(() => (this.rowStates[driverId].isLoading = true));
    let result = await this.store.repository.addDriverDistrict(
      districtId,
      driverId
    );

    result.match({
      ok: () =>
        runInAction(() => {
          this.rowStates[driverId].districts = [...districts, district];
          this.rowStates[driverId].inputValue = "";
          this.setUnusedDistrictByDriverId(driverId);
        }),
      err: (err) => message.error(err.message),
    });

    runInAction(() => (this.rowStates[driverId].isLoading = false));
  };

  handleInputChange = (value: string, driverId: string) => {
    this.rowStates[driverId].inputValue = value;
  };

  deleteDistrictDriver = async (districtId: string, driverId: string) => {
    runInAction(() => (this.secondTableRowStates[districtId].isLoading = true));

    const result = await this.store.repository.deleteDistrictDriver(
      districtId,
      driverId
    );

    result.match<void>({
      ok: () =>
        runInAction(() => {
          this.secondTableRowStates[districtId].drivers =
            this.secondTableRowStates[districtId].drivers.filter(
              (driver: any) => driver.driverId !== driverId
            );

          this.drivers = this.secondTableRowStates[districtId].drivers;
          this.secondTableRowStates[districtId].unusedDrivers = this.drivers
            .filter(
              (driverObj) =>
                this.secondTableRowStates[districtId].drivers.findIndex(
                  (driver: any) => driver.driverId === driverObj.driverId
                ) === -1
            )
            .sort();
        }),
      err: (err) => message.error(err.message),
    });

    runInAction(
      () => (this.secondTableRowStates[districtId].isLoading = false)
    );
  };

  handleClose = async (district: District, driverId: string) => {
    runInAction(() => (this.rowStates[driverId].isLoading = true));

    const result = await this.store.repository.deleteDriverDistrict(
      district.id!,
      driverId
    );

    result.match<void>({
      ok: () =>
        runInAction(() => {
          this.rowStates[driverId].districts = this.rowStates[
            driverId
          ].districts.filter((v: District) => v.id !== district.id);
          this.setUnusedDistrictByDriverId(driverId);
        }),
      err: (err) => message.error(err.message),
    });

    runInAction(() => (this.rowStates[driverId].isLoading = false));
  };

  setUnusedDistrictByDriverId(driverId: string): void {
    this.rowStates[driverId].unusedDistricts = this.districts
      .filter(
        (d) =>
          this.rowStates[driverId].districts.findIndex(
            (district: District) => district.id === d.id
          ) === -1
      )
      .sort();
  }

  async getDrivers(): Promise<void> {
    runInAction(() => (this.isLoading = true));

    let result = await this.store.repository.getDriversDistrictList();
    result.match<void>({
      ok: (data: AllDistrictsAndDriversWithDistricts) => {
        runInAction(() => {
          this.districts = data.districts;
          this.columns = [
            { title: "Drivers", dataIndex: "drivers" },
            {
              title: "Districts",
              dataIndex: "data",
              render: (driverId: string) => (
                <DistrictsInputView store={this} driverId={driverId} />
              ),
            },
          ];
          data.drivers.forEach((driver) => {
            this.rowStates[driver.id] = {
              districts: driver.driver_districts.map(
                (districtDriver) => districtDriver.district
              ),
              inputValue: "",
              inputVisible: false,
            };
            this.setUnusedDistrictByDriverId(driver.id);
          });
          this.tableData = data.drivers.map(
            (driver: DriverWithDistricts, index: number) => ({
              key: index,
              drivers: driver.name,
              data: driver.id,
            })
          );
          this.isLoading = false;
        });
      },
      err: (err: Failure) => {
        console.log(err);
        return message.error(err.message);
      },
    });
  }

  async getDistricts(): Promise<void> {
    runInAction(() => (this.isLoading = true));

    let result = await this.store.repository.getDistrictsDriverList();
    result.match<void>({
      ok: (list: Array<DistrictWithDrivers>) => {
        runInAction(() => {
          this.columns = [
            { title: "Districts", dataIndex: "districts" },
            {
              title: "Drivers",
              dataIndex: "data",
              render: (districtId: string) => (
                <DriverWithDistrictsView store={this} districtId={districtId} />
              ),
            },
            {
              title: "Load Factor",
              dataIndex: "loadFactor",
              render: (districtProps: {
                loadFactor: number;
                districtId: string;
              }) => (
                <InputNumber
                  min={1}
                  max={7}
                  defaultValue={districtProps.loadFactor}
                  onChange={(loadFactor) => {
                    if (loadFactor != null) {
                      this.store.repository.updateDistrictLoadFactor(
                        loadFactor,
                        districtProps.districtId
                      );
                    }
                  }}
                />
              ),
            },
            {
              title: (
                <span>
                  Schedule limitation&nbsp;
                  {!_limitedUsers.includes(window.UserData.email) && (
                    <Tooltip title="Only some people have access to this field">
                      <QuestionCircleTwoTone />
                    </Tooltip>
                  )}
                </span>
              ),
              dataIndex: "deliveryDays",
              render: (districtProps: {
                deliveryDays: number;
                districtId: string;
              }) => (
                <InputNumber
                  disabled={!_limitedUsers.includes(window.UserData.email)}
                  min={1}
                  max={7}
                  defaultValue={districtProps.deliveryDays}
                  onChange={(deliveryDays) => {
                    if (deliveryDays != null) {
                      this.store.repository.updateDistrictDeliveryDays(
                        deliveryDays,
                        districtProps.districtId
                      );
                    }
                  }}
                />
              ),
            },
            {
              title: "Off days",
              dataIndex: "offDays",
              render: (districtProps: {
                offDays: number[];
                districtId: string;
              }) => (
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select days"
                  defaultValue={
                    districtProps?.offDays?.length >= 1
                      ? districtProps.offDays
                      : undefined
                  }
                  options={_offDaysOptions}
                  onChange={(offDays: number[]) => {
                    if (offDays != null) {
                      this.store.repository.updateDistrictOffDays(
                        offDays,
                        districtProps.districtId
                      );
                    }
                  }}
                />
              ),
            },
            {
              title: "Enabled",
              dataIndex: "districtEnabled",
              render: (districtProps: {
                districtId: string;
                enabled: boolean;
              }) => (
                <DistrictSwitch
                  enabled={districtProps.enabled}
                  onUpdate={async (enabled) => {
                    if (enabled != null) {
                      this.store.repository.updateDistrictEnabled(
                        districtProps.districtId,
                        enabled
                      );
                    }
                  }}
                />
              ),
            },
          ];
          list.forEach((districtWithDrivers) => {
            this.secondTableRowStates[districtWithDrivers.id] = {
              drivers: districtWithDrivers.driver_districts.map((driver) => {
                return {
                  driverName: driver.driver.name,
                  driverId: driver.driverId,
                };
              }),
            };
          });
          this.tableData = list.map(
            (district: DistrictWithDrivers, index: number) => {
              return {
                key: index,
                districts: district.name,
                data: district.id,
                districtEnabled: {
                  enabled: district.enabled,
                  districtId: district.id,
                },
                loadFactor: {
                  loadFactor: district.loadFactor,
                  districtId: district.id,
                },
                offDays: {
                  offDays: district.offDays,
                  districtId: district.id,
                },
                deliveryDays: {
                  deliveryDays: district.deliveryDays,
                  districtId: district.id,
                },
              };
            }
          );
          this.isLoading = false;
        });
      },
      err: (err: any) => message.error(err.message),
    });
  }
}
