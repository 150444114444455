import React, { useState, useEffect } from "react";
import { Switch } from "antd";

interface DistrictSwitchProps {
  enabled: boolean;
  onUpdate: (value: boolean) => Promise<void>;
}

export const DistrictSwitch: React.FC<DistrictSwitchProps> = ({
  enabled,
  onUpdate,
}) => {
  const [isEnabled, setIsEnabled] = useState(enabled);

  useEffect(() => {
    setIsEnabled(enabled);
  }, [enabled]);

  const handleChange = (value: boolean) => {
    if (value != null) {
      onUpdate(value)
        .then(() => {
          setIsEnabled(value);
        })
        .catch((error) => {
          console.error("Failed to update district enabled state:", error);
        });
    }
  };

  return (
    <Switch
      style={{
        backgroundColor: isEnabled ? "#1890ff" : "grey",
      }}
      checkedChildren="ON"
      unCheckedChildren="OFF"
      checked={isEnabled}
      onChange={handleChange}
    />
  );
};
