import moment from "moment";
import { Button, DatePicker, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { OrderTableColumnsInterface } from "../../interfaces/views/OrderTableColumnsInterface";

const { RangePicker } = DatePicker;

const getDateFilterColumns = (dataIndex: string) => ({
  filterDropdown: ({
    setSelectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) => (
    <div>
      <RangePicker
        ranges={{
          Yesterday: [
            moment().subtract({ days: 1 }),
            moment().subtract({ days: 1 }),
          ],
          Today: [moment(), moment()],
          Tomorrow: [moment().add({ days: 1 }), moment().add({ days: 1 })],
          "This Week": [moment().startOf("week"), moment().endOf("week")],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
        }}
        // TODO: dates type
        onChange={(dates: any) => {
          if (!dates) return clearFilters!();
          dates[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          dates[1].set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
          dates[0].format();
          dates[1].format();
          return setSelectedKeys([dates]);
        }}
        style={{ marginBottom: 8 }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
          padding: "8px",
          paddingTop: "0",
        }}
      >
        <Button role="reset" onClick={clearFilters} size="small">
          Reset
        </Button>

        <Button
          type="primary"
          role="search"
          onClick={() => confirm({ closeDropdown: true })}
          // style={{ width: btnWidth }}
          icon={<SearchOutlined />}
          size="small"
        />
      </div>
    </div>
  ),
});

export let ORDER_TABLE_COLUMNS: OrderTableColumnsInterface[] = [
  {
    dataIndex: "id",
    key: "id",
    title: "Order #",
    sorter: true,
    width: "5%",
  },
  {
    dataIndex: "shortCode",
    key: "shortCode",
    title: "Short Code",
    sorter: true,
  },
  {
    dataIndex: "status",
    key: "status",
    title: "Status",
  },
  {
    dataIndex: "deliverTo",
    key: "deliverTo",
    title: "Deliver to",
    width: "10%",
    ellipsis: true,
  },
  {
    dataIndex: "totalCents",
    key: "totalCents",
    title: "Total price",
    width: "4.5%",
  },
  {
    dataIndex: "plannedDate",
    key: "plannedDate",
    title: "Planned date",
    sorter: true,
    render: (field, _record) => {
      if (field == null) return "";
      return field.format("MMM Do, h:mm a");
    },
    ...getDateFilterColumns("plannedDate"),
  },
  {
    dataIndex: "deliveredDate",
    key: "deliveredDate",
    title: "Deliver date",
    sorter: true,
    render: (field, _record) => {
      if (field == null) return "";
      return field.format("MMM Do, h:mm a");
    },
    ...getDateFilterColumns("deliveredDate"),
  },
  {
    dataIndex: "firstPosition",
    key: "firstPosition",
    width: "8%",
    title: "1st Position",
    render: (position: any) => position,
  },
  {
    dataIndex: "resale",
    key: "resale",
    title: "Reventa",
    align: "center",
    width: "7%",
    render: (resale: boolean) => {
      if (resale) return <Tag color="green">Yes</Tag>;
      return <Tag color="red">No</Tag>;
    },
    filters: [
      { text: <Tag color="green">Yes</Tag>, value: true },
      { text: <Tag color="red">No</Tag>, value: false },
    ],
    sorter: (a: any, b: any) => a.resale - b.resale,
  },
  {
    dataIndex: "driverName",
    key: "driverName",
    title: "DRV",
    filters: [],
    sorter: true,
  },
  {
    dataIndex: "updatedAt",
    key: "updatedAt",
    title: "Updated at",
    sorter: true,
    render: (field, _record) => {
      if (field == null) return "";
      return field.format("MMM Do, h:mm a");
    },
    ...getDateFilterColumns("updatedAt"),
  },
  {
    dataIndex: "createdAt",
    key: "createdAt",
    title: "Created at",
    sorter: true,
    render: (field, _record) => moment(field).format("YYYY-MM-DD, HH:mm"),
    ...getDateFilterColumns("createdAt"),
  },
];
