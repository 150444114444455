import { FormInstance, message } from "antd";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { UserRepository } from "../data/repository/user_repository";
import {
  UserCreateInterface,
  UserDepartment,
  UserInterface,
} from "../interfaces/data/UserRepositoryInterface";
import { UserTableColumnsInterface } from "../interfaces/views/UserTableColumnsInterface";
import { getUserTableColumns } from "./settings/USER_TABLE_COLUMNS";

export interface UserTableParametres {
  pagination: UserTableParamatresPagination;
  filters: UserTableParametresFilters;
  sorters?: any;
}

export interface SubscribeToUsersForTableParams {
  limit: number | null;
  offset: number | null;
  where: any;
  sorters: any;
}

export interface UserTableParametresFilters {
  deliveredDate?: any;
  driverName?: any;
  plannedDate?: any;
  status?: any;
  _or?: any[];
}

export interface UserTableParamatresPagination extends TablePaginationConfig {
  pageSize?: number;
  total?: number;
}

export interface UserStoreMeta {
  roles: UserStoreMetaRoles[];
  departments: UserDepartment[];
}

export interface UserStoreMetaRoles {
  role: string;
}

export class UserStore {
  repository: UserRepository;
  table: UserTable;
  modal: NewUserModal;
  _initialized: boolean = false;
  meta: UserStoreMeta = { roles: [], departments: [] };

  constructor(repository: UserRepository) {
    makeAutoObservable(this, { repository: false });

    this.repository = repository;
    this.table = new UserTable(this);
    this.modal = new NewUserModal(this);
  }

  async init(): Promise<void> {
    if (this._initialized) return;

    const result = await this.repository.getUserMeta();
    if (result.error) message.error(result.error);
    this.meta.roles = result.user;
    this.meta.departments = result.department;

    this._initialized = true;
    this.table.init();
    runInAction(() => (this.modal.loading = false));
  }
}

export const NewUserModalDocumentType = {
  dni: "dni",
  ce: "ce",
  ptp: "ptp",
};
export class NewUserModal {
  store: UserStore;

  visible: boolean = false;
  loading: boolean = true;

  documentType: string = NewUserModalDocumentType.dni;

  error?: string;
  formComponent?: FormInstance<any>;
  form: UserCreateInterface = {};

  constructor(store: UserStore) {
    makeAutoObservable(this, { formComponent: false });

    this.store = store;
  }

  show(): void {
    this.visible = true;
  }

  hide(): void {
    this.visible = false;
  }

  onFormChange(newForm: UserCreateInterface): void {
    this.form = newForm;
  }

  setDocumentType(type: string): void {
    this.documentType = type;
  }

  async onSubmit(): Promise<void> {
    let form = { ...this.form };

    if (
      !form.email ||
      !form.name ||
      !form.password ||
      !form.role ||
      !form.departmentId
    ) {
      message.error("Fill all required fields.");
    }

    runInAction(() => (this.loading = true));

    const result = await this.store.repository.createUser(form);
    runInAction(() => (this.loading = false));

    if (result.error) {
      message.error(`${result.error}`, 10);
    } else {
      this.hide();
      this.formComponent!.resetFields();
      message.success("User created successfully!");
    }
  }
}

const UserTableState = {
  loading: "LOADING",
  loaded: "LOADED",
  error: "ERROR",
};
class UserTable {
  store: UserStore;

  switchChecked: boolean = false;

  error: string = "";
  users?: UserCreateInterface[];

  state: string = UserTableState.loading;
  customFilters: any;
  tableParametres: UserTableParametres = {
    pagination: {
      current: 1,
      defaultCurrent: 1,
      defaultPageSize: 20,
    },
    filters: {},
  };
  rows: any[] = [];
  columns: UserTableColumnsInterface[] = getUserTableColumns((user: UserInterface) => this.onBan(user));

  constructor(store: UserStore) {
    makeAutoObservable(this, {
      columns: false,
      customFilters: false,
    });

    this.store = store;
  }

  async init(): Promise<void> {
    await this.updateRows();
  }

  async onSwitch(checked: boolean): Promise<void> {
    runInAction(() => {
      this.switchChecked = checked;
    });

    await this.updateRows();
  }

  async updateRows(): Promise<void> {
    runInAction(() => (this.state = UserTableState.loading));

    const params = this.getQueryParametres(this.switchChecked);

    await this.store.repository.subscribeToUsers({
      ...params,
      handlers: {
        onData: (response: any) =>
          runInAction(() => {
            this.columns.find(
              (v: UserTableColumnsInterface) => v.dataIndex === "name"
            )!.filters = this.users?.map((user: any) => ({
              text: user.name,
              value: user.id,
            }));
            this.tableParametres.pagination.total = response.total;
            this.rows = response.users.map((row: any) => ({
              ...row,
              key: row.id,
            }));
            this.state = UserTableState.loaded;
          }),
        onError: (error: string) =>
          runInAction(() => {
            this.state = UserTableState.error;
            this.error = error;
          }),
      },
    });
  }

  onSearch(searchText: string) {
    if (searchText === "") {
      this.customFilters = null;
    } else {
      let like = `%${searchText}%`;
      this.customFilters = {
        _or: [{ name: { _ilike: like } }, { email: { _ilike: like } }],
      };
    }
    this.updateRows();
  }

  onChange({ pagination, filters, sorters }: UserTableParametres): void {
    this.tableParametres = { pagination, filters, sorters };
    this.updateRows();
  }

  getQueryParametres(activeUsers: boolean): SubscribeToUsersForTableParams {
    const limit =
      this.tableParametres.pagination.pageSize ||
      this.tableParametres.pagination.defaultPageSize ||
      null;
    const offset =
      (limit ?? 0) * (this.tableParametres.pagination.current! - 1);

    let filters: any = {};
    let andFilters: any[] = [];

    const tableFilters = toJS(this.tableParametres.filters) as any;
    if (tableFilters.name) {
      // Значением tableFilters.name является user.id (смотри init метод)
      filters.id = { _in: tableFilters.name };
    }

    if (tableFilters.role) {
      filters.role = { _in: tableFilters.role };
    }

    if (andFilters.length > 0) {
      filters._and = andFilters;
    }

    filters.blocked = { _eq: activeUsers !== undefined && activeUsers !== null && activeUsers ? true : false };

    if (this.customFilters) {
      filters = { ...filters, ...this.customFilters };
    }

    const tableSorters = toJS(this.tableParametres.sorters);

    // TODO: any to type
    let sorters: any = {};
    if (tableSorters && tableSorters.order) {
      // Убираем "end" из слов "ascend" или "descend"
      const sorterOrder = tableSorters.order.replace("end", "");
      sorters[tableSorters.field! as string] = sorterOrder;
    }
    if (Object.keys(sorters).length === 0) sorters = null;

    return {
      limit: limit,
      offset: offset,
      where: filters,
      sorters,
    };
  }

  onBan(user: UserInterface): void {
    this.store.repository
      .blockOrUnblockUser(user.id, !user.blocked)
      .then((messageText) => {
        runInAction(() => {
          message.success(messageText);
          this.updateRows();
        });
      })
      .catch((error) => {
        runInAction(() => {
          message.error(error);
        });
      });
  }


  get isLoading(): boolean {
    return this.state === UserTableState.loading;
  }
}
