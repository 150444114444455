import Card from "antd/lib/card";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { OrderChange } from "../../../../interfaces/data/OrderRepositoryInterface";
import { order_change_formatters } from "../components/formatters";
import { RowChangeView } from "./components/RowChangeView";

export const OrderChangeView: React.FC<{
  change: OrderChange;
}> = observer(({ change }) => {
  var createdAtDate = moment(change!.createdAt).format("DD, MMM");
  var createdAtTime = moment(change!.createdAt).format("HH:mm");
  var editorName = change!.editor?.name;

  if (editorName! == null) {
    editorName = "admin";
  } else if (change?.editor.id === window.UserData.sub) {
    editorName = "You";
  }

  return (
    <div style={{ padding: "4px 8px" }}>
      <Card
        style={{ padding: 0 }}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{editorName}</span>
            <div>
              <span
                style={{ color: "#1890ffaa", fontWeight: 400, fontSize: 14 }}
              >
                {createdAtDate}{" "}
              </span>
              <span style={{ color: "#1890ff" }}>{createdAtTime}</span>
            </div>
          </div>
        }
      >
        <div>
          <span>
            {Object.keys(change!.old)
              // Фильтруем только те поля, которые нам нужно отследить
              .filter((row: string) => order_change_formatters.includes(row))
              .map((row) => RowChangeView(row, change!))}
          </span>
        </div>
      </Card>
    </div>
  );
});
