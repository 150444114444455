import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";

import "../styles/login.css";
import { CenteredSpin } from "./CenteredSpin";
import { signOut } from "../core/providers/AuthFirebaseProvider";

export const LogoutView: React.FC = () => {
  // const { logout } = useAuth0();
  // logout({ returnTo: window.location.origin });
  signOut().then(() => (window.location.href = "/"));

  return <CenteredSpin />;
};
