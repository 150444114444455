import moment from "moment";

export const order_change_formatter: any = {
  statusName: {
    name: "status",
    format: (v?: string) => v,
  },
  plannedDate: {
    name: "planned date",
    format: (v?: string) => (v ? `${moment(v).format("DD, MMMM HH:mm")}` : ""),
  },
  plannedDateDuration: {
    name: "duration",
    format: (v?: number) => `${v ?? 0} minutes`,
  },
  deliveredDate: {
    name: "deliver date",
    format: (v?: string) => (v ? `${moment(v).format("DD, MMMM HH:mm")}` : ""),
  },
  totalCents: {
    name: "total",
    format: (v?: string) => v,
  },
  driverID: {
    name: "driver id",
    format: (v?: string) => v,
  },
  warehouseID: {
    name: "driver id",
    format: (v?: string) => v,
  },
  reasonRefusal: {
    name: "refuse reason",
    format: (v?: string) => `"${v?.trim() ?? ""}"`,
  },
};

export const order_change_formatters = Object.keys<string>(
  order_change_formatter
);
