import Layout, { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import { observer } from "mobx-react-lite";
import { OrderPageViewInterface } from "../../interfaces/views/OrderPageViewInterface";
import { OrderDrawerView } from "./OrderDrawer/OrderDrawerView";
import { OrderHeaderView } from "./components/OrderHeaderView";
import { OrderTableView } from "./components/OrderTableView";

export const OrderPageView: React.FC<OrderPageViewInterface> = observer(
  ({ orderStore }) => {
    return (
      <>
        <OrderHeaderView order={orderStore} />
        <Layout>
          <Content>
            <OrderTableView table={orderStore.table} />
          </Content>
          <Sider
            width="460px"
            style={{
              backgroundColor: "#fff",
              padding: "16px",
              overflowY: "auto",
              height: "calc(100vh - 96px)",
              boxShadow: "0 0 3px #0002",
            }}
            className="order-drawer"
            hidden={orderStore.drawer.hidden}
          >
            <OrderDrawerView drawerStore={orderStore.drawer} />
          </Sider>
        </Layout>
        /
      </>
    );
  }
);
