import {
  OrderChange,
  OrderState,
} from "../../../../../interfaces/data/OrderRepositoryInterface";
import { order_change_formatter } from "../../components/formatters";

export function RowChangeView(
  row: keyof OrderState,
  change: OrderChange
): JSX.Element {
  return (
    <div style={{ padding: 2 }} key={row}>
      {RowView(order_change_formatter[row].name)}{" "}
      {OldValueView(order_change_formatter[row].format(change.old[row]))}
      {" →  "}
      {NewValueView(order_change_formatter[row].format(change.new[row]))}
    </div>
  );
}

export const RowView = (child: React.ReactElement | string) => (
  <p
    style={{
      display: "inline-block",
      background: "#1890ff",
      color: "white",
      padding: "0 2px",
      margin: 0,
      borderRadius: 5,
    }}
  >
    {child}
  </p>
);

const OldValueView = (child: React.ReactElement | string) => (
  <span
    style={{
      // textDecoration: "line-through",
      color: "#AAA",
    }}
  >
    {child}
  </span>
);

const NewValueView = (child: React.ReactElement | string) => (
  <span
    style={{
      // textDecoration: "underline",
      textDecorationColor: "#5E5",
      color: "#1890ff",
    }}
  >
    {child}
  </span>
);
