import { Tag } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { DistrictsTable } from "../../../mobx/DistrictsStore";

export const DriverWithDistrictsView: React.FC<{
  store: DistrictsTable;
  districtId: string;
}> = observer(({ store, districtId }) => {
  return (
    <div>
      {store.secondTableRowStates[districtId].drivers.map(
        (driver: { driverName: string; driverId: string }) => {
          return (
            <Tag
              key={driver.driverId}
              color={"blue"}
              onClose={() =>
                store.deleteDistrictDriver(districtId, driver.driverId)
              }
              closable
              style={{ fontSize: 14, margin: 0 }}
            >
              {driver.driverName}
            </Tag>
          );
        }
      )}
    </div>
  );
});
