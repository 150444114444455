import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
import {
  OrderInterface,
  Positions,
  PositionsProduct,
} from "../interfaces/data/OrderRepositoryInterface";
import {
  AddressParts,
  ListOfRows,
  UpdateQuantityInterface,
} from "../interfaces/stores/OrderDrawerInterface";
import { OrderStore } from "./OrderStore";
import { Flow } from "../core/utils/flow";
import { OrderChangeStore } from "./OrderChanges";

const deepEqual = require('deep-equal');
export const OrderDrawerState = {
  hidden: "HIDDEN",
  loading: "LOADING",
  form: "FORM",
  multi: "MULTI",
  error: "ERROR",
};

export const time_ranges = [
  ["08:30", "10:00"],
  ["09:00", "11:00"],
  ["10:00", "12:00"],
  ["11:00", "13:00"],
  ["12:00", "14:00"],
  ["13:00", "15:00"],
  ["14:00", "16:00"],
  ["15:00", "17:00"],
  ["16:00", "18:00"],
];

export class OrderDrawer {
  constructor(store: OrderStore) {
    makeAutoObservable(this, {
      manualPriority: false,
      addressPartsLine: false,
      recalcFlow: false,
      totalQuantity: false,
      positionsChanged: false,
    });

    this.store = store;
    this.changesStore = new OrderChangeStore(this);
  }

  changesStore: OrderChangeStore;
  isShowChangesModal = false;

  state: string = OrderDrawerState.hidden;
  form!: OrderInterface;
  initialForm!: OrderInterface;

  current_id: Nullable<number> = null;
  positionsChanges: {
    updateQuantity: UpdateQuantityInterface[];
    delete: any[];
    insert: any[];
  } = {
      updateQuantity: [],
      delete: [],
      insert: [],
    };
  store: OrderStore;

  isVisibleModalAddPosition: boolean = false;
  enterAddressManually: boolean = false;
  manualPriority: boolean = true;

  isVisibleStatusAlertModal = false;
  formChanges: any;

  isLoadingTimeRangeWithLoadFactor = true;
  workloads: any = [];

  addressParts: AddressParts = {};

  totalPriceCalculating = false;
  positionsChanged = false;

  //TODO(skyfet): указать типы
  products: any;
  recalcIncorrectMessage?: string;

  recalcFlow = new Flow();

  isPhonePressed = false;

  toggleIsPhonePressed(userId: string, orderId: number) {
    this.isPhonePressed = true;
    this.store.repository.insertUserViewsLog({ userId, orderId });
  }

  showChangesModal() {
    this.changesStore.getChanges(this.form.order?.id!);
    this.isShowChangesModal = true;
  }

  closeChangesModal() {
    this.isShowChangesModal = false;
  }

  showModal = (formChanges: any) => {
    this.isVisibleStatusAlertModal = true;
    this.formChanges = formChanges;
  };

  handleOk = () => {
    this.isVisibleStatusAlertModal = false;

    this.onFormSubmit(this.formChanges, true);
  };

  handleCancel = () => {
    this.isVisibleStatusAlertModal = false;
  };

  //Outside action
  showOrder(id: number): void {
    this.current_id = id;
    this.isPhonePressed = false;
    this.state = OrderDrawerState.loading;
    this.store.repository.getOrderChangeHistory(id).then(console.log);
    this.store.repository
      .getOrderById(id)
      .then((orderResponse: OrderInterface) => {
        if (orderResponse.error) {
          return console.error(
            "Drawer get order exception: ",
            orderResponse.error
          );
        }

        runInAction((): void => {
          this.form = orderResponse;
          this.initialForm = orderResponse;

          this.form.order!.totalQuantity = this.totalQuantity;
          this.products = orderResponse.products;
          this.state = OrderDrawerState.form;
          this.addressParts.country =
            orderResponse.order?.client?.address?.country;
          this.addressParts.city = orderResponse.order?.client?.address?.city;
          this.addressParts.district =
            orderResponse.order?.client?.address?.district;
          this.setDistrictWorkload();
        });
      });
  }

  switchToManuallyAddressInput(): void {
    this.enterAddressManually = true;
  }

  switchToAutocompleteAddressInput(): void {
    this.enterAddressManually = false;
  }

  setAddressPart(
    part: "country" | "city" | "district",
    value: string,
    manual = false
  ): void {
    this.addressParts[part] = value;

    if (manual) {
      this.manualPriority = true;
    }
  }

  async setDistrictWorkload() {
    this.isLoadingTimeRangeWithLoadFactor = true;

    let isOrderExists =
      this.form.order !== undefined && this.form.order !== null;
    let isDistrictIdExists =
      this.form.order?.client.districtId !== undefined &&
      this.form.order.client.districtId !== null;

    if (isOrderExists && isDistrictIdExists) {
      this.store.repository
        .getDistrictWorkload(
          this.form.order!.plannedDate!,
          this.form.order!.client.districtId
        )
        .then((response) => {
          if (response.error) {
            return message.error(response.error.message);
          }

          // Добавляем поле "час", чтобы было удобнее читать данные
          let districtWorkloadsWithHour =
            response.data?.workloads.map((districtWorkload) => ({
              ...districtWorkload,
              hour: moment(districtWorkload.timeperiod.split("+")[0]).hour(),
            })) ?? [];

          this.workloads = time_ranges.map((range) => {
            var start = parseInt(range[0].split(":")[0]);
            var end = parseInt(range[1].split(":")[0]);
            var workloadsInRange = districtWorkloadsWithHour.filter(
              (w) => w.hour >= start && w.hour < end
            );

            var workloadSum = workloadsInRange
              .map((w) => w.workload)
              .reduce((prev, current) => prev + current, 0);

            var workloadAvg = workloadSum / (end - start);

            return {
              workload: workloadAvg,
              loadFactor: response.data?.loadFactor ?? 1,
              range: range,
            };
          });

          this.isLoadingTimeRangeWithLoadFactor = false;
        });
    } else {
      // this.isLoadingTimeRangeWithLoadFactor = false;
    }
  }

  showMultiAction(listOfRows: ListOfRows[]): void {
    this.state = OrderDrawerState.loading;
    this.store.repository
      .getOrderListById(listOfRows.map((i) => i.id))
      .then((r: any) => {
        if (r.error) {
          return console.error("Drawer get orders exception: ", r.error);
        }
        runInAction(() => {
          this.form = r;
          this.form.orders?.forEach(
            (order) =>
            (order.totalQuantity = order!.positions
              .map((p) => p.quantity)
              .reduce((a, b) => a + b, 0))
          );
          this.state = OrderDrawerState.multi;
        });
      });
  }

  get canChangeStatus(): boolean {
    return this.form.orders!.every(
      (v) => v.status!.name === this.form.orders![0].status!.name
    );
  }

  hide(): void {
    this.state = OrderDrawerState.hidden;
    this.current_id = null;
  }

  recalcOrderPrice() {
    this.recalcIncorrectMessage =
      this.totalQuantity === 2
        ? "The total quantity in the basket cannot be 2"
        : undefined;

    this.positionsChanged = !deepEqual(this.initialForm.order?.positions, this.form.order?.positions);

    this.totalPriceCalculating = true;
    this.recalcFlow.onFinish = () =>
      runInAction(() => (this.totalPriceCalculating = false));

    const order = this.form.order!;

    const promise = () =>
      this.store.repository.calcOrderPrice(
        order.id!,
        order.umbrellaID!,
        mapPositionsToRemote(order.positions),
        window.UserData.email
      );
    // TODO(skyfet): implement types
    const action = (result: any) => {
      if (result.error) {
        message.error(result.error.message);
      } else {
        let total = calculateTotalByResult(result);
        let items = result.recalcOrder.orderItems;
        let newPositions = order.positions.map((p) => {
          p.totalPrice = items.find(
            (item: any) => item.productId === p.productID
          ).totalPrice;
          return p;
        });
        runInAction(() => {
          order.positions = newPositions;
          order.totalCents = total;
        });
      }
    };

    this.recalcFlow.addToFlow(promise, action);
  }

  onChangeQuantity(quantity: number, positionId: number) {
    this.form.order!.positions.find((p) => p.id === positionId)!.quantity =
      quantity;

    this.recalcOrderPrice();
  }

  onDeletePosition(position: Positions) {
    if (this.form.order!.positions.length <= 1) {
      message.warn("The order must contain at least one line item!");
      return;
    }
    this.form.order!.positions = this.form.order!.positions.filter(
      (p) => p.productID !== position.productID
    );

    this.recalcOrderPrice();
  }

  onAddToOrderPosition(product: PositionsProduct) {
    this.form.order!.positions.push({
      product,
      productID: product.id,
      quantity: 1,
    });

    this.recalcOrderPrice();

    this.showOrHiddenModalAddPosition();
  }

  onLatLngChange(v: string) {
    try {
      const [lat, lng] = v.split(", ");
      this.addressParts.lat = lat;
      this.addressParts.lng = lng;
    } catch (e) {
      message.error("Incorrect coordinates format");
    }
  }

  showOrHiddenModalAddPosition(): void {
    this.isVisibleModalAddPosition = !this.isVisibleModalAddPosition;
  }

  async onFormSubmit(form: OrderInterface, isModalStatusConfirmation = false) {
    if (this.recalcIncorrectMessage) {
      message.error(this.recalcIncorrectMessage);
      return;
    }

    try {
      let formChanges = this.getFormChanges(form);

      var isDeliveredStatus = formChanges.order.statusName === "Delivered";
      if (
        formChanges.order.statusName === "DriverConfirmed" &&
        !this.form.order?.plannedDate &&
        !formChanges.order.plannedDate
      ) {
        message.error(
          "You need to provide a planned date to change the status to DriverConfirmed"
        );
        return;
      }

      if (!isModalStatusConfirmation && isDeliveredStatus) {
        this.showModal(formChanges);
        return;
      }

      await this.store.repository.updateOrderData({
        orderId: this.form.order!.id!,
        order: formChanges.order,
        clientId: this.form.order!.client.id,
        client: formChanges.client,
        addressId: this.form.order!.client.address.id,
        address: formChanges.address,
      });

      if (this.positionsChanged) {
        await this.store.repository.editOrderPositions(
          this.form.order!.id!,
          this.form.order!.umbrellaID!,
          mapPositionsToRemote(this.form.order!.positions),
          window.UserData.email
        );
      }
      message.success("Success.");
      this.showOrder(this.form.order!.id!);
    } catch (e: any) {
      message.error(e.message);
    }
  }

  async onMultiFormSubmit(changes: any): Promise<void> {
    try {
      if (!this.canChangeStatus && changes.statusName) {
        throw Error(
          "You can`t change the status of an order if all orders do not have the same status"
        );
      }
      this.form.orders?.forEach(async (value) => {
        let result = await this.store.repository.updateOrderById({
          id: value.id!,
          order: changes,
        });
        if (result.error) {
          throw result.error;
        }
      });

      message.success("Success.");
    } catch (e) {
      message.error(`${e}`);
    }
  }

  getFormChanges(formData: any): any {
    Object.keys(formData).forEach((name) =>
      Object.keys(formData[name]).forEach((key) => {
        if (!formData[name][key]) delete formData[name][key];
      })
    );
    if (formData.order.plannedDate) {
      if (!formData.date.time) {
        throw Error("You need to provide a planned date with a time range");
      }
      var times = formData.date.time.split(",");
      formData.order.plannedDate = moment(
        `${formData.order.plannedDate.format("YYYY-MM-DD")}T${times[0]}`
      ).toISOString();
      formData.order.plannedDateDuration = Math.floor(
        (Date.parse(`2000-01-01T${times[1]}`) -
          Date.parse(`2000-01-01T${times[0]}`)) /
        60000
      );
    }

    formData.address = { ...formData.address };
    if (formData.address.latlng) {
      //???
    }
    Object.keys(this.addressParts).forEach((k) => {
      let key: keyof AddressParts = k as keyof AddressParts;
      if (this.addressParts[key]) {
        formData.address[key] = this.addressParts[key];
      }
    });
    return formData;
  }

  get addressPartsLine(): string {
    return [
      this.addressParts.district,
      this.addressParts.city,
      this.addressParts.country,
    ]
      .filter((v) => v)
      .join(", ");
  }

  get productsWithoutOrder(): PositionsProduct[] {
    let existingProductsIds = this.form.order!.positions?.map(
      (position) => position.product.id
    );
    if (!existingProductsIds) return this.products;
    return this.products.filter(
      (product: PositionsProduct) =>
        !existingProductsIds.find((v) => v === product.id)
    );
  }

  get hidden() {
    return this.state === OrderDrawerState.hidden;
  }

  get totalQuantity() {
    return this.form
      .order!.positions.map((p) => p.quantity)
      .reduce((a, b) => a + b, 0);
  }

  get plannedDateDefaultValue(): moment.Moment | undefined {
    let startIso: any = this.form.order?.plannedDate;

    if (!startIso) return undefined;

    let start = moment(startIso);

    return start;
  }
  get plannedDateTimeDefaultValue(): string | undefined {
    let startIso: any = this.form.order?.plannedDate;
    let duration = this.form.order?.plannedDateDuration;

    if (!startIso || !duration) return undefined;

    var startMoment = moment(startIso);
    let start = startMoment.format("HH:mm");
    let end = startMoment.add(duration, "minutes").format("HH:mm");

    return `${start} - ${end}`;
  }
}

function calculateTotalByResult(result: any): number {
  if (
    !result.recalcOrder.orderItems ||
    result.recalcOrder.orderItems.length === 0
  )
    return 0;
  return (
    result.recalcOrder.orderItems
      .map((item: any) => item.totalPrice)
      .reduce((a: number, b: number) => a + b, 0) +
    result.recalcOrder.deliveryPrice
  );
}

function mapPositionsToRemote(positions: Positions[]) {
  return positions.map((position) => ({
    productId: position.productID,
    qty: position.quantity,
  }));
}
