const drawerStyles = {
  aside: {
    overflowY: 'scroll',
    height: 'calc(100vh - 96px)',
  },
  drawer: {
    padding: "8px",
    marginTop: "30px",
  },
  selectionSection: {
    display: "grid",
    gridTemplateColumns: '1fr 1fr',
  },
  antFormItem: {
    marginBottom: '12px'
  }
};

export default drawerStyles;